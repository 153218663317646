<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Profile</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dashboard-area pt-100">
    <div class="container">
        <div class="create-information">
            <form [formGroup]="profileaccount">
                <!-- <div class="create-information-btn">
                    <a routerLink="/">Upload Cover Photo</a>
                    <a routerLink="/">Upload Your CV</a>
                </div> -->

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="text" formControlName="msisdn" class="form-control" placeholder="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Your Email</label>
                            <input type="email" formControlName="email" class="form-control" placeholder="hello@gable.com">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" formControlName="firstName" class="form-control" placeholder="Aikin Ward">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" formControlName="lastName" class="form-control" placeholder="Aikin Ward">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Company</label>
                            <input type="text" formControlName="name" class="form-control" placeholder="UX/UI Designer">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Address</label>
                            <input type="text" formControlName="address" class="form-control" placeholder="">
                        </div>
                    </div>
                    <!-- <div class="col-lg-12">
                        <div class="form-group">
                                <label for="inputField" class="btn btn-info">{{filelabel}}</label>
                                <input type="file" id="inputField" class="form-control-file"
                                    placeholder="Choose Logo" style="display:none"
                                    (change)="onFileChange($event)">
                        </div>
                    </div> -->
               </div>
            </form>
        </div>

        <!-- <div class="create-education create-education-two">
            <div class="create-education-wrap">
                <div class="create-education-left">
                    <h3>Education</h3>
                </div>

                <div class="create-education-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add Education</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" placeholder="Under Graduate">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Degree</label>
                            <input type="text" class="form-control" placeholder="BSC in Computer Science">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Institute</label>
                            <input type="text" class="form-control" placeholder="Gable University of Design & Arts">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Year</label>
                            <input type="text" class="form-control" placeholder="2016-2019">
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Skill</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add Skill</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control" placeholder="Frontend Design">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="skill">
                            <p>Percentage</p>
                            <div class="skill-bar skill1 skill3 wow slideInLeft animated">
                                <span class="skill-count1">60%</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control" placeholder="UX/UI Design">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="skill">
                            <p>Percentage</p>
                            <div class="skill-bar skill1 skill4 wow slideInLeft animated">
                                <span class="skill-count1">95%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Social Links</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add New</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Facebook</label>
                            <input type="text" class="form-control" placeholder="https://www.facebook,com">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Instagram</label>
                            <input type="text" class="form-control" placeholder="https://www.instagram,com">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Linedin</label>
                            <input type="text" class="form-control" placeholder="https://www.linkedin,com">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Dribbble</label>
                            <input type="text" class="form-control" placeholder="https://www.dribble,com">
                        </div>
                    </div>
                </div>
            </form>
        </div> -->
        
        <div class="text-left">
            <button type="submit" class="btn create-ac-btn" (click)="onSave()">Save</button>
        </div>
    </div>
</div>