import { Component, Inject, OnInit } from '@angular/core';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit {
  candidateDetails: any;

  constructor(@Inject(AppStore) public store: Store<AppState>, private service: AppService,) { }

  ngOnInit(): void {
    this.updateState();
  }

  updateState(){
    this.candidateDetails = this.store.getState().selectedCandidate;
    if(this.candidateDetails){
      this.fetchProfileImages(this.candidateDetails.uuid);      
    } 
  }

  candidateImage: string;
  fetchProfileImages(uuid: string){
    try {
        this.service.fetchProfileImages(uuid, (res)=>{
          if(res.data.file){
            this.candidateImage = "data:image/jpeg;base64," + res.data.file;
          }            
        })
    } catch (error) {
      console.log(error);
    }
  }

  candidateCV: string = '';
  candidateCVFile: string = '';
  downloadCV(){
    try {
      this.service.downloadCV(this.candidateDetails.uuid, (res)=>{        
        if(res && res.data.file && res.data.file!=''){
          this.candidateCV = res.data.file;
          this.candidateCVFile = res.data.fileName;
          this.convertAndDownload(this.candidateCV, this.candidateCVFile)
        }
      })
    } catch (error) {
      console.log(error);
      
    }
  }

  convertAndDownload(base64String: string, fileName: string): void {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  }

}
