import { Component, Inject, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { AppService } from "src/app/app.service";
import Swal from "sweetalert2";
import * as UserActions from "src/app/store/app.actions";
import { Store } from "redux";
import { Router } from "@angular/router";
import { AppState } from "src/app/store/app.state";
import { AppStore } from "src/app/store/app.store";
import { createCandidateREQ } from "./create-candidateDTO";

@Component({
    selector: "app-create-candidates",
    templateUrl: "./create-candidates.component.html",
    styleUrls: ["./create-candidates.component.scss"],
})
export class CreateCandidatesComponent {
    mobileNumberPrefix: string;
    mobileNumberPattern: string;
    errorstr: string = "";
    showOtpPage: boolean = false;
    filelabel = "Upload Doc";
    isLoggedIn: boolean = false;
    username: string;

    createCandidate: FormGroup;
    get msisdn() { return this.createCandidate.get("msisdn"); }
    get firstName() { return this.createCandidate.get("firstName"); }
    get lastName() { return this.createCandidate.get("lastName"); }
    get address() { return this.createCandidate.get("address"); }
    get qualification() { return this.createCandidate.get("qualification"); }
    get picture() { return this.createCandidate.get("picture"); }
    get document() { return this.createCandidate.get("document"); }

    constructor(
        private appservice: AppService,
        private router: Router,
        @Inject(AppStore) public store: Store<AppState>
    ) {
        store.subscribe(() => this.updateState());
    }

    updateState() {
        this.isLoggedIn = this.store.getState().isLoggedIn;
        this.username = this.store.getState().username;
    }

    ngOnInit(): void {
        this.updateState();
        this.createCandidate = new FormGroup(
            {
                msisdn: new FormControl("", [Validators.required]),
                firstName: new FormControl("", [Validators.required]),
                lastName: new FormControl("", [Validators.required]),
                address: new FormControl("", [Validators.required]),
                qualification: new FormControl("", [Validators.required]),
                picture: new FormControl(""),
                document: new FormControl("", [Validators.required]),
            }
        );

        this.setDefaultValues();
        this.setMobilePatterns(null);
    }

    passwordsMatch(
        passwordKey: string,
        confirmPasswordKey: string
    ): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control) {
                return null;
            }
            const password = control.get(passwordKey);
            const confirmPassword = control.get(confirmPasswordKey);
            if (!password.value || !confirmPassword.value) {
                return null;
            }

            if (password.value !== confirmPassword.value) {
                confirmPassword.setErrors({ mustMatch: true });
                return { passwordMismatch: true };
            }
            return null;
        };
    }

    setDefaultValues() {
        this.createCandidate.reset();
    }

    setMobilePatterns(prefix) {
        this.mobileNumberPrefix = prefix == null ? "252" : prefix;
        this.mobileNumberPattern = "^(" + this.mobileNumberPrefix + ")[0-9]*";
    }

    onSubmit() {
			if (this.createCandidate.valid) {
				let REQ: createCandidateREQ = {
					cvFile: this.docFile.replace(/.*;base64,/, ''),
					cvFileName: this.docFileName,
					location: this.address.value,
					msisdn: this.msisdn.value,
					name: this.firstName.value + " " + this.lastName.value,
					pictureFile: this.profileFile.replace(/.*;base64,/, ''),
					pictureFileName: this.profileFileName,
                    qualification: this.qualification.value,
				};

				this.appservice.createCandidate(
					REQ,
					(result) => {
						if (result.result === "ok") {
                            Swal.fire("Success", "Candidate Created Successfully", "success");
                           
                            this.router.navigateByUrl('/');
						} else {
							this.errorstr =
									"Error in creating Candidate: " + result.debugInfo;
							Swal.fire("Error", this.errorstr, "error");
						}
					}
				);
			} else {
					this.createCandidate.markAllAsTouched();
			}
    }

    profileFile: string = "";
		profileFileName: string = "";
		docFileName: string = "";
    docFile: string = "";

    onFileChange(event, type: string) {
        const file = event.target.files[0];				
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (type === "profile") {
							let base64 = reader.result;
							this.profileFile = base64.toString();
							this.profileFileName = file.name;
            } else {
							let base64 = reader.result;
							this.docFile = base64.toString();
							this.docFileName = file.name;
            }
        };
    }
}
