<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>{{type}} Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>{{type}} Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="job-details-area pt-100">
    <div class="container">
        <a class="job-details-btn col-1 text-center my-0 py-2" href="" (click)="Onback()">Go back</a>
        <div class="row mt-1">
            <div class="col-lg-8">
                <div class="job-details-item" *ngIf="jobDetail == null">
                    <h2>No Data Found</h2>
                </div>
                <div class="job-details-item" *ngIf="jobDetail != null">
                    <div class="job-overview">
                        <h3>{{type}} Description</h3>                        
                        <!-- <p>{{jobDetail.description}}</p> -->
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                        <ngx-editor [editor]="editor" [ngModel]="jobDetail.description" [disabled]="true"
                            [placeholder]="'No Description'"></ngx-editor>

                        <!-- <pdf-viewer *ngIf="jobDetail.hasFile" [src]="filecontentdata" [rotation]="0"
                            [original-size]="false" [show-all]="true" [fit-to-page]="true" [zoom]="1"
                            [zoom-scale]="'page-width'" [stick-to-page]="true" [render-text]="true"
                            [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                            style="display: block;"></pdf-viewer> -->
                        <h3>{{type}} ApplyLink</h3>
                        <a href="{{jobDetail.applyLink}}" class="myellipsis">{{jobDetail.applyLink}}</a>    
                        <h3>{{type}} File</h3>
                        <ngx-extended-pdf-viewer *ngIf="jobDetail.hasFile" [src]="filecontentdata"
                            [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true"
                            [showPresentationModeButton]="true" [showDownloadButton]="false"
                            [height]="'75vh'"></ngx-extended-pdf-viewer>

                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="job-details-item" *ngIf="jobDetail != null">
                    <div class="job-overview">
                        <h3>{{type}} Summary</h3>

                        <ul>
                            <li>
                                <i class="icofont-hour-glass"></i>
                                <p>Deadline</p>
                                <span *ngIf="jobDetail.categoryId"> {{jobDetail.deadLine}}</span>
                                <span *ngIf="!jobDetail.categoryId"> {{jobDetail.expireDate}}</span>
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                <p>Location</p>
                                <span> {{jobDetail.location}}</span>
                            </li>
                            <li>
                                <i class="icofont-calendar"></i>
                                <p>CreatedAt</p>
                                <span *ngIf="jobDetail.categoryId"> {{jobDetail.createdAt}}</span>
                                <span *ngIf="!jobDetail.categoryId"> {{jobDetail.postedAt}}</span>
                            </li>
                            <!-- <li>
                            <i class="icofont-ui-call"></i>
                            <a href="tel:+07554332322">Call : +07 554 332 322</a>
                        </li> -->

                            <li>
                                <i class="icofont-ui-email"></i>
                                <p>Link</p>
                                <a href="{{jobDetail.webLink}}" class="myellipsis">{{jobDetail.webLink}}</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>