import { Component, Inject, OnInit } from '@angular/core';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEYS } from 'src/app/helpers/Enums';
import * as UserActions from 'src/app/store/app.actions';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public appservice: AppService, @Inject(AppStore) public store: Store<AppState>, private router: Router) {
    store.subscribe(() => this.updateState());
  }

  isLoggedIn: boolean = false;
  username: string;
  
  ngOnInit(): void {
    this.updateState();
  }

  updateState(){
    this.isLoggedIn = this.store.getState().isLoggedIn;
    console.log("User is logged in: ", this.isLoggedIn);
    this.username = this.store.getState().username;
    console.log("User Name: ", this.username);    
  }

  logout(){
    this.appservice.endActiveSession();
  }

}
