import {
  Action,
  ActionCreator
} from 'redux';
import { Course, IvrPromptDto, JobDetail} from './app.state';

//0
export interface customAction extends Action {
  payload: any
}

//1
export const SET_CURRENTUSERDETAILS = 'Set Current User Details';
export const setCurrentUserDetails: ActionCreator<customAction> =
  (data: any) => ({
    type: SET_CURRENTUSERDETAILS,
    payload: data
  });

//7
export const SETROLES = 'Set ROLES ';
export interface SetrolesAction extends Action {
  roles: any;
}
export const setRoles: ActionCreator<SetrolesAction> =
  (inroles) => ({
    type: SETROLES,
    roles: inroles
  })


//9
export const SETUSERS = 'Set USERS  ';
export interface SetusersAction extends Action {
  users: any;
}
export const setUsers: ActionCreator<SetusersAction> =
  (inusers) => ({
    type: SETUSERS,
    users: inusers
  })

//10
export const SETUSERINROLES = 'Set USERINROLES  ';
export interface SetuserinrolesAction extends Action {
  userinroles: any;
}
export const setUserinroles: ActionCreator<SetuserinrolesAction> =
  (inuserinroles) => ({
    type: SETUSERINROLES,
    userinroles: inuserinroles
  })

//11
export const SETUSERASSIGNROLES = 'Set USERASSIGNROLES  ';
export interface SetuserassignrolesAction extends Action {
  userassignroles: any;
}
export const setUserassignroles: ActionCreator<SetuserassignrolesAction> =
  (inuserassignroles) => ({
    type: SETUSERASSIGNROLES,
    userassignroles: inuserassignroles
  })


//IVR Prompt Details 
export const SETIVRPROMPTCONTENTS = 'Set IVRPROMPTCONTENTS  ';
export const setIVRPromptContents: ActionCreator<customAction> =
  (inivrpromptcontents: IvrPromptDto[]) => ({
    type: SETIVRPROMPTCONTENTS,
    payload: inivrpromptcontents
  })

export const ISLOGGEDIN = 'Is Logged In';
export const setIsLoggedIn: ActionCreator<customAction> =
  (isLoggedIn: boolean) => ({
    type: ISLOGGEDIN,
    payload: isLoggedIn
  })

export const SETUSERNAME = 'User Name';
export const setUserName: ActionCreator<customAction> =
  (username: string) => ({
    type: SETUSERNAME,
    payload: username
  })

export const SETJOBDETAIL = 'Set Job Detail';
export const setJobDetail: ActionCreator<customAction> =
  (data: JobDetail) => ({
    type: SETJOBDETAIL,
    payload: data
  })

export const SETCOURSEDETAIL = 'Set Course Detail';
export const setCourseDetail: ActionCreator<customAction> =
  (data: Course) => ({
    type: SETCOURSEDETAIL,
    payload: data
  })

export const SETSELECTEDCANDIDATE = 'Set Selected Candidate';
export const setSelectedCandidate: ActionCreator<customAction> =
  (data: Course) => ({
    type: SETSELECTEDCANDIDATE,
    payload: data
  })