<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Create Account</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Create Employer Account</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="create-account-area pt-100 pb-100">
    <div class="container">
        <div *ngIf="errorstr" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{ errorstr }}
        </div>
        <form [formGroup]="createaccount" *ngIf="!showOtpPage">
            <!-- <div class="create-photo">
                <div class="already-create">
                    <span>Already create an account?</span>
                    <a routerLink="/login">Sign In</a>
                </div>


                <div class="row align-items-center">

                    <div class="col-lg-4">
                        <div class="create-photo-item">
                            <div class="create-photo-left">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="form-group">
                                            <i class="icofont-photobucket"></i>
                                            <label for="inputField" class="btn btn-info">{{filelabel}}</label>
                                            <input type="file" id="inputField" class="form-control-file"
                                                formControlName="logo" placeholder="Choose Logo" style="display:none"
                                                (change)="onFileChange($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="create-photo-item">
                            <div class="create-photo-right">
                                <div class="form-group">
                                    <input type="text" class="form-control" minlength="12" maxlength="12"
                                        pattern="{{mobileNumberPattern}}" formControlName="msisdn"
                                        placeholder="Phone Number">
                                    <div class="alert alert-warning"
                                        *ngIf="msisdn && msisdn.invalid && (msisdn.dirty || msisdn.touched)">
                                        <div *ngIf="msisdn.errors.pattern">Mobile Number should start with
                                            {{mobileNumberPrefix}}
                                        </div>
                                        <div *ngIf="msisdn.errors.required">Mobile Number is required</div>
                                        <div *ngIf="msisdn.errors.minlength || msisdn.errors.maxlength">Mobile
                                            Number must be of 12 characters
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password">
                                    <div class="alert alert-warning"
                                        *ngIf="password && password.errors && password.errors.required && (password.dirty || password.touched)">
                                        Password is required</div>
                                    <div class="alert alert-warning"
                                        *ngIf="password && password.errors && password.errors.minlength && (password.dirty || password.touched)">
                                        Password must be at least 6 characters</div>
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Confirm Password"
                                        formControlName="confirmPassword">
                                    <div class="alert alert-warning"
                                        *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.required && (confirmPassword.dirty || confirmPassword.touched)">
                                        Confirm Password is required</div>
                                    <div class="alert alert-warning"
                                        *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.mustMatch && (confirmPassword.dirty || confirmPassword.touched)">
                                        Passwords must match</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="create-information">
                <!-- <h3>Basic Information</h3> -->

                <!-- <div class="create-information-btn">
                    <a routerLink="/">Upload Cover Photo</a>
                    <a routerLink="/">Upload Your CV</a>
                </div> -->

                <div class="row">
                   
                    <div class="form-group col-lg-6">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" minlength="12" maxlength="12"
                            pattern="{{mobileNumberPattern}}" formControlName="msisdn"
                            placeholder="">
                        <div class="alert alert-warning"
                            *ngIf="msisdn && msisdn.invalid && (msisdn.dirty || msisdn.touched)">
                            <div *ngIf="msisdn.errors.pattern">Mobile Number should start with
                                {{mobileNumberPrefix}}
                            </div>
                            <div *ngIf="msisdn.errors.required">Mobile Number is required</div>
                            <div *ngIf="msisdn.errors.minlength || msisdn.errors.maxlength">Mobile
                                Number must be of 12 characters
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Your Email</label>
                            <input type="email" class="form-control" formControlName="email">
                            <div class="alert alert-warning"
                                *ngIf="email && email.errors && email.errors.required && (email.dirty || email.touched)">
                                Email is required</div>
                            <div class="alert alert-warning"
                                *ngIf="email && email.errors && email.errors.email && (email.dirty || email.touched)">
                                Email must be a valid email address</div>
                        </div>
                    </div>


                    <div class="form-group col-lg-6">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder=""
                            formControlName="password">
                        <div class="alert alert-warning"
                            *ngIf="password && password.errors && password.errors.required && (password.dirty || password.touched)">
                            Password is required</div>
                        <div class="alert alert-warning"
                            *ngIf="password && password.errors && password.errors.minlength && (password.dirty || password.touched)">
                            Password must be at least 6 characters</div>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Confirm Password</label>
                        <input type="password" class="form-control" placeholder=""
                            formControlName="confirmPassword">
                        <div class="alert alert-warning"
                            *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.required && (confirmPassword.dirty || confirmPassword.touched)">
                            Confirm Password is required</div>
                        <div class="alert alert-warning"
                            *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.mustMatch && (confirmPassword.dirty || confirmPassword.touched)">
                            Passwords must match</div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" formControlName="firstName">
                            <div class="alert alert-warning"
                                *ngIf="firstName && firstName.errors && firstName.errors.required && (firstName.dirty || firstName.touched)">
                                firstName is required</div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" formControlName="lastName">
                            <div class="alert alert-warning"
                                *ngIf="lastName && lastName.errors && lastName.errors.required && (lastName.dirty || lastName.touched)">
                                lastName is required</div>
                        </div>
                    </div>



                    <!-- <div class="col-lg-6">
                        <div class="form-group">
                            <label>Date of Birth</label>
                            <input type="date" class="form-control">
                        </div>
                    </div> -->

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Company Name</label>
                            <input type="text" class="form-control" formControlName="name">
                            <div class="alert alert-warning"
                                *ngIf="name && name.errors && name.errors.required  && (name.dirty || name.touched)">
                                name is required</div>
                            <div class="alert alert-warning"
                                *ngIf="name && name.errors && name.errors.minlength && (name.dirty || name.touched)">
                                name must be at least 4 characters</div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6">
                        <div class="form-group">F
                            <label>Job Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div> -->

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Address:</label>
                            <input type="text" class="form-control" formControlName="address">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                                <label for="inputField" class="btn btn-info">{{filelabel}}</label>
                                <input type="file" id="inputField" class="form-control-file"
                                    placeholder="Choose Logo" style="display:none"
                                    (change)="onFileChange($event)">
                        </div>
                    </div>

                    <!-- <div class="col-lg-12">
                        <div class="form-group">
                            <div class="gender-area">
                                <span>Gender</span>
                                <input type="radio" name="gender" id="male" value="male" checked>
                                <label for="male">Male</label>
                                <input type="radio" name="gender" id="female" value="female">
                                <label for="female">Female</label>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea id="your_message" class="form-control" rows="8"></textarea>
                        </div>
                    </div> -->
                </div>

            </div>

            <!-- <div class="create-education">
            <div class="create-education-wrap">
                <div class="create-education-left">
                    <h3>Education</h3>
                </div>

                <div class="create-education-right">
                    <a routerLink="/">Add Education</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Degree</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Institute</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Year</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Skill</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add Skill</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="skill">
                            <p>Percentage</p>
                            <div class="skill-bar skill1 wow slideInLeft animated">
                                <span class="skill-count1">70%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Social Links</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add New</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Facebook</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Instagram</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Linedin</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Dribbble</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </form>
        </div> -->

            <div class="text-left">
                <button type="submit" class="btn create-ac-btn" (click)="onSubmit()">Save</button>
                <button type="submit" class="btn create-blue-btn mx-3" (click)="setDefaultValues()">Reset</button>
            </div>
        </form>

        <form [formGroup]="otpform" *ngIf="showOtpPage">
            <div class="create-information">
                <h3>Confirm Signup</h3>
                <div class="row align-items-center">
                    <div *ngIf="errorstr" class="alert alert-danger alert-dismissible fade show" role="alert">
                        {{ errorstr }}
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Enter Otp</label>
                            <input type="text" class="form-control" placeholder="" formControlName="otp">
                            <div class="alert alert-warning"
                                *ngIf="otp && otp.errors && otp.errors.required  && (otp.dirty || otp.touched)">
                                otp is required</div>
                            <div class="alert alert-warning"
                                *ngIf="otp && otp.errors && otp.errors.minlength && (otp.dirty || otp.touched)">
                                otp must 6 characters</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-left">
                <button type="submit" class="btn create-ac-btn" (click)="onSubmit()">Validate</button>
                <button type="submit" class="btn create-blue-btn  mx-3" (click)="setDefaultValues()">Resend</button>
            </div>
        </form>

    </div>
</div>