import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import * as UserActions from 'src/app/store/app.actions';
import { Store } from 'redux';
import { Router } from '@angular/router';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  createaccount: FormGroup;
  otpform: FormGroup;
  mobileNumberPrefix: string;
  mobileNumberPattern: string;
  errorstr: string = "";
  showOtpPage: boolean = false;
  filelabel = "Choose Logo";
  isLoggedIn: boolean = false;
  username: string;

  constructor(private appservice: AppService, private router: Router, @Inject(AppStore) public store: Store<AppState>) { 
    store.subscribe(() => this.updateState());
  }

  updateState(){
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;
  }

  ngOnInit(): void {
    this.updateState();
    
    if(this.isLoggedIn){
      this.router.navigate(['']);
    }
    this.createaccount = new FormGroup({
      msisdn: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      logo: new FormControl(''),
    }, {
      validators: [this.passwordsMatch('password', 'confirmPassword')]
    });


    this.otpform = new FormGroup({
      otp: new FormControl('', [Validators.required, Validators.minLength(4)]),
    });

    this.setDefaultValues();
    this.setMobilePatterns(null);
  }

  passwordsMatch(passwordKey: string, confirmPasswordKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control) { return null; }
      const password = control.get(passwordKey);
      const confirmPassword = control.get(confirmPasswordKey);
      if (!password.value || !confirmPassword.value) {
        return null;
      }

      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ mustMatch: true });
        return { passwordMismatch: true };
      }
      return null;
    };
  }

  get msisdn() { return this.createaccount.get('msisdn'); }
  get name() { return this.createaccount.get('name'); }
  get password() { return this.createaccount.get('password'); }
  get confirmPassword() { return this.createaccount.get('confirmPassword'); }
  get email() { return this.createaccount.get('email'); }
  get firstName() { return this.createaccount.get('firstName'); }
  get lastName() { return this.createaccount.get('lastName'); }
  get address() { return this.createaccount.get('address'); }
  get logo() { return this.createaccount.get('logo'); }

  get otp() { return this.otpform.get('otp'); }

  setDefaultValues() {
    if (!this.showOtpPage) {
      this.createaccount.reset();
    }

  }

  resendOtpAgain(){
    this.appservice.resendotp(this.createaccount.value, (result)=>{
      if(result.result.toUpperCase() == "ERROR"){
        Swal.fire('Warning', result.errorDesc, 'info');
      }
      this.otpform.reset();
    });
  }

  setMobilePatterns(prefix) {
    this.mobileNumberPrefix = prefix == null ? "252" : prefix;
    this.mobileNumberPattern = "^(" + this.mobileNumberPrefix + ")[0-9]*";
  }

  onSubmit() {

    if (!this.showOtpPage) {
      if (this.createaccount.valid) {
        this.appservice.signup(this.createaccount.value, (result) => {
          if (result.result === 'ok') {
            //this.setDefaultValues();
            this.showOtpPage = true;
            this.store.dispatch(UserActions.setUserName(this.createaccount.value.msisdn));
          }
          else {
            console.log("Unable to create user because: ",result);
            this.errorstr = "Error in creating User: " + result.debugInfo;
            Swal.fire('Error', this.errorstr, 'error');
          }

        });
      } else {
        this.createaccount.markAllAsTouched();
      }
    } else {
      if (this.otpform.valid) {

        let postData = { "msisdn": this.msisdn.value, "code": this.otp.value };
        this.appservice.confirmsignup(postData, (result) => {
          if (result.result === 'ok') {
            // this.setDefaultValues();
            // this.showOtpPage = true;
            console.log("OTP validated redirecting to Job List page");
            this.store.dispatch(UserActions.setIsLoggedIn(true));

            setTimeout(() => {
              this.appservice.getCompanyProfile((data) => {

                this.store.dispatch(UserActions.setCurrentUserDetails(data));
                this.router.navigateByUrl('job-list');
              });
            }, 500);

            //this.router.navigateByUrl('job-list');
          }
          console.log("Unable to authenticate you by OTP: ", result);
          this.errorstr = (result.result === 'ok') ? "User created successfully" : result.debugInfo;
          Swal.fire(result.result === 'ok'? 'Success': 'Error', this.errorstr, 'info');
        });
      } else {
        this.otpform.markAllAsTouched();
      }
    }
  }

  onFileChange(event){
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      console.log("FileUpload -> files", fileList);
      this.filelabel = fileList[0].name;
    }
    else{
      this.filelabel = "Choose Logo";
    }
    const file1 = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file1);
    reader.onload = () => {
      //console.log(reader.result);
      let finalstring : any = reader.result;
      //finalstring = finalstring.split(',')[1];

      console.log(finalstring);

      this.createaccount.patchValue({
        logo: finalstring
      });

      
    };
  }
}


