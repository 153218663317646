import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoggedIn: boolean = false;

   constructor(public appservice: AppService, @Inject(AppStore) public store: Store<AppState>, private router: Router) {
    store.subscribe(() => this.updateState());
  }
  
  ngOnInit(): void {
    this.updateState();
  }

  updateState(){
    this.isLoggedIn = this.store.getState().isLoggedIn;   
  }

}
