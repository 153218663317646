import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState, Course, JobDetail } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import { Location } from '@angular/common';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { Subject, tap } from 'rxjs';
import { Editor, Toolbar, toHTML } from 'ngx-editor';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {

  jobDetail: JobDetail;
  courseDetail: Course;
  isLoggedIn: boolean = false;
  username: string;
  type: string = '';
  eduQualification: any[] = [];
  skillsList: any[] = [];
  returnUrl: string = "";
  filecontentdata = '';
  public base64 = new Subject<string>();
  id: any = null;
  editor: Editor;
  html: string = '';
  toolbar: Toolbar = [];

  constructor(private service: AppService, private route: ActivatedRoute, @Inject(AppStore) public store: Store<AppState>,
    private router: Router, private _location: Location) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  updateState() {
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;

    if (this.id == null) {
      if (this.type.toUpperCase() != "COURSE") {
        this.jobDetail = this.store.getState().jobDetail;
        console.log("Job Detail: ", this.jobDetail);
        this.fetchFile(this.jobDetail);
      } else {
        this.courseDetail = this.store.getState().courseDetail;
        console.log("Course Detail: ", this.courseDetail);
        this.fetchFile(this.courseDetail);
      }
    }
  }

  fetchFile(ele) {
    if (ele != null && ele.hasFile) {
      this.service.getImageFile(ele.uuid, this.type.toUpperCase(), (response) => {
        if (response.data != undefined) {
          this.filecontentdata = "data:application/pdf;base64," + response.data.file;                   
          this.base64.next(this.filecontentdata);
        }
      });
    }
  }

  ngOnInit(): void {
    this.editor = new Editor(
     { keyboardShortcuts: true,}
    );
    //this.html = toHTML({});
    this.store.subscribe(() => this.updateState());

    this.updateState();

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl;
    })

    this.route.queryParams
      .subscribe(params => {
        this.type = params.type;
        // if (this.type == null || this.type == undefined) {
        //   this.type = 'Job';
        // }

        this.id = params.id;

        if(this.type !== ''){
          this.getData(this.id);
        }

        
        // console.log(this.type);

      }
      );
  }

  Onback() {
    console.log(this.returnUrl);
    this.router.navigate([this.returnUrl], { queryParams: { type: this.type } });
  }

  getData(id: string) {

    let searchForm = { 'name': null, 'catgroyId': null, 'location': null };
    this.service.getjobPostList(searchForm, this.type.toUpperCase(), (result: any) => {

      let jobPostList = result.data;

      for (let i = 0; i < jobPostList.length; i++) {
        let ele = jobPostList[i];
        if (ele.id === Number(id)) {
          if (this.type.toUpperCase() != "COURSE") {
            this.jobDetail = ele;
            console.log("Job Detail: ", this.jobDetail);
            this.fetchFile(this.jobDetail);
          } else {
            this.courseDetail = ele;
            console.log("Course Detail: ", this.courseDetail);
            this.fetchFile(this.courseDetail);
          }
          break;
        }
      }

    });
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }



}
