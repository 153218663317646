/**
 * Counter Reducer
 */
import { Reducer, Action } from 'redux';
import {  AppState, UserDetail, JobDetail, Course } from './app.state';
import * as UserActions from './app.actions';

const initialState: AppState = {
  IsAuthenticated: true, 

  currentuserdetail : {
    id : "",
    email : "",
    firstName : "",
    lastName : "",
  },
  isLoggedIn : false,
  token : "abcd",
  username: "",
  jobDetail: null,
  courseDetail: null,
  selectedCandidate: null,
};

// Create our reducer that will handle changes to the state
export const counterReducer: Reducer<AppState> =
  (state: AppState = initialState, action: Action): AppState => {
    switch (action.type) {

      case UserActions.SET_CURRENTUSERDETAILS:
        if((<UserActions.customAction>action).payload !== null)
        {
          const data = (<UserActions.customAction>action).payload;
          return Object.assign({}, state, { currentuserdetail: data });
        }
        else{
          let userdetail = {
            id : undefined,
            email : undefined,
            firstname : undefined,
            lastname : undefined,
            rolename : ""
          }
          return Object.assign({}, state, { currentuserdetail: userdetail , isLoggedIn : false, token : ""});
        }
      case UserActions.ISLOGGEDIN:
        if ((<UserActions.customAction>action).payload !== null) {
          const data: string = (<UserActions.customAction>action).payload
          return Object.assign({}, state, { isLoggedIn: data });
        } 
      case UserActions.SETUSERNAME:
        if ((<UserActions.customAction>action).payload !== null) {
          const data: string = (<UserActions.customAction>action).payload
          return Object.assign({}, state, { username: data });
        }
      case UserActions.SETJOBDETAIL:
        //if ((<UserActions.customAction>action).payload !== null) {
          const jobdata: JobDetail = (<UserActions.customAction>action).payload
          return Object.assign({}, state, { jobDetail: jobdata });
        //}

      case UserActions.SETCOURSEDETAIL:  
      //if ((<UserActions.customAction>action).payload !== null) {
        const data: Course = (<UserActions.customAction>action).payload
        return Object.assign({}, state, { courseDetail: data });
      //}
       
      case UserActions.SETSELECTEDCANDIDATE:  
      //if ((<UserActions.customAction>action).payload !== null) {
        const sSandidate: Course = (<UserActions.customAction>action).payload
        return Object.assign({}, state, { selectedCandidate: sSandidate });
      //}

      default:
        return state;
    }
  };
