import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isLoggedIn: boolean = false;
  username: string;
  data: any;
  filelabel = "Choose Logo";
  profileaccount: FormGroup;

  constructor(private service: AppService, private http: HttpClient, private router: Router, @Inject(AppStore) public store: Store<AppState>) {
    store.subscribe(() => this.updateState());
    this.profileaccount = new FormGroup({
      msisdn: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address: new FormControl(''),
    });
    this.msisdn.disable();
    
  }

  updateState() {
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;
    this.data = this.store.getState().currentuserdetail?.data;
    this.setDefaultValues();
  }

  ngOnInit(): void {
    this.updateState();



    this.setDefaultValues();
  }

  get msisdn() { return this.profileaccount.get('msisdn'); }
  get name() { return this.profileaccount.get('name'); }
  get email() { return this.profileaccount.get('email'); }
  get firstName() { return this.profileaccount.get('firstName'); }
  get lastName() { return this.profileaccount.get('lastName'); }
  get address() { return this.profileaccount.get('address'); }

  setDefaultValues() {
    this.profileaccount.reset();

    this.profileaccount.patchValue({
      name: this.data?.name,
      msisdn: this.data?.msisdn,
      email: this.data?.email,
      firstName: this.data?.firstName,
      lastName: this.data?.lastName,
      address: this.data?.address,
    });

  }

  onFileChange(event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      console.log("FileUpload -> files", fileList);
      this.filelabel = fileList[0].name;
    }
    else {
      this.filelabel = "Choose Logo";
    }
    const file1 = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file1);
    reader.onload = () => {
      //console.log(reader.result);
      let finalstring: any = reader.result;
      //finalstring = finalstring.split(',')[1];

      console.log(finalstring);

      // this.createaccount.patchValue({
      //   logo: finalstring
      // });


    };
  }

  onSave() {
    if (this.profileaccount.valid) {
      this.service.saveCompanyProfile(this.profileaccount.value, (result) => {
        if (result?.result === 'ok') {
          Swal.fire('Success', 'Profile udpated successfully', 'success');
        }
        else {
          Swal.fire('Error', result.debugInfo, 'error');
        }
      });
    } else {
      this.profileaccount.markAllAsTouched();
    }

  }

}
