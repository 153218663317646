import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import Swal from 'sweetalert2';
import * as UserActions from 'src/app/store/app.actions';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
  providers: [DatePipe]
})
export class JobListComponent implements OnInit {

  categories = [];
  jobpostData: any;
  searchText: any;
  p: number = 1;
  errorstr: string | undefined;
  jobpostForm = new FormGroup({
    id: new FormControl(),
    categoryId: new FormControl(),
    deadLine: new FormControl(),
    location: new FormControl(),
    name: new FormControl(),
    description: new FormControl(),
    webLink: new FormControl(),
    file: new FormControl(''),
    fileName: new FormControl('')
  });

  searchForm = new FormGroup({
    categoryId: new FormControl(''),
    name: new FormControl(),
    location: new FormControl(),
  });

  successStr: string | undefined;
  jobPostList: any[] = [];
  modelTitle: string | undefined;
  type: string;
  isLoggedIn: boolean = false;
  username: string;
  fileMap: Map<string, string> = new Map();
  userdetail: any;

  constructor(public datepipe: DatePipe, private service: AppService, @Inject(AppStore) public store: Store<AppState>,
    private route: ActivatedRoute, private router: Router) {
    store.subscribe(() => this.updateState());
  }

  updateState() {
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;
    this.userdetail = this.store.getState().currentuserdetail;
  }

  ngOnInit(): void {
    this.updateState();

    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }

        this.type = params.type;
        if (this.type == null || this.type == undefined) {
          this.type = 'Job';
        }

        // console.log(this.type); // popular
        this.loadNewCategoryComponent();
      }
      );

  }

  private loadNewCategoryComponent() {
    this.getData(this.searchText);
    this.service.getCompanyCategories(0, 25, (result: any) => {
      this.categories = result.data;
    });
  }

  onSearch() {
    this.getData(this.searchForm.value);
  }
  onClearSearch() {
    this.searchForm.reset();
    this.getData(this.searchForm.value);
  }
  getData(searchText: any) {

    this.service.getjobPostList(this.searchForm.value, this.type.toUpperCase(), (result: any) => {
      this.jobPostList = [];
      this.jobPostList = result.data;

      try {
        this.jobPostList = this.jobPostList.filter((ele: any) => (!this.isExpired(ele.deadLine)));
      } catch (error) {
        console.log("Error in filtering " + this.type);
      }



      this.fileMap.clear();
      for (let i = 0; i < this.jobPostList.length; i++) {
        let ele = this.jobPostList[i];
        if (ele.hasCompanyLogo && !this.fileMap.has(ele.companyUuid)) {
          this.service.getCompanyLogo(ele.companyUuid, (response) => {
            if (response != null && response != undefined && response ) {
              this.fileMap.set(ele.companyUuid, response.data.file);
            }
          });
        }
      }
      if (!this.username) {
        this.jobPostList = this.jobPostList.filter((ele: any) => ele.status == null || ele.status.toUpperCase() == "APPROVED");


      }
    });
  }
  get deadLine() { return this.jobpostForm.get('deadLine'); }

  // Delete Class Rooms
  onAdd() {
    this.jobpostData = null;
    this.jobpostForm.reset();
    this.modelTitle = 'Add Job Post';

  }
  onEdit(jobpost: any) {
    if (jobpost.status == 'Approved') {
      //$("#closeModel").click(); 
      Swal.fire('Sorry, You cant edit approved post!', '', 'info')

    }
    if (jobpost.status == 'Rejected') {
      //$("#closeModel").click(); 
      Swal.fire('Sorry, You cant edit rejected post!', '', 'info')

    }
    const [dateComponents, timeComponents] = jobpost.deadLine.split(' ');
    const [month, day, year] = dateComponents.split('-');
    const [hours, minutes, seconds] = timeComponents.split(':');
    const dDate = new Date(year, month, day, hours, minutes, seconds);

    //year+'-'+day+'-'+month
    this.jobpostData = jobpost.id;

    this.modelTitle = 'Edit Job Post';
    //let updatedDeadLineData = this.datepipe.transform(jobpost.deadLine, 'MM/dd/yyyy');
    this.jobpostForm.patchValue(
      {
        id: jobpost.id,
        categoryId: jobpost.categoryId,
        deadLine: new Date(year + '-' + day + '-' + month),
        location: jobpost.location,
        name: jobpost.name,
        description: jobpost.description,
        webLink: jobpost.webLink,
        file: jobpost.file,
        fileName: jobpost.fileName
      }
    );
  }
  onDelete(jobpost: any, event) {
    event.stopPropagation();
    
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((res) => {
      if (res.isConfirmed) {

        //your code for deletion, etc.
        this.service.deleteJob(jobpost.id, this.type.toUpperCase(), (result: any) => {
          // console.log("result : " + result);
          if (result.result == 'ok') {
            this.getData(this.searchText);
            Swal.fire('Job deleted successfully!', '', 'success')
          } else {
            Swal.fire('Sorry! Something went wrong', '', 'info')
          }
        });
      }
    });
  }
  update(id: string) {

    let updatedDeadLineData = this.datepipe.transform(this.jobpostForm.value.deadLine, 'yyyy-MM-dd h:mm:ss');
    this.jobpostForm.value.deadLine = updatedDeadLineData;

    if (this.jobpostForm.valid) {
      this.service.updatejobpost(this.jobpostForm.value, this.type, (result: any) => {
        if (result.result == 'ok') {
          this.errorstr = '';
          Swal.fire('Job updated successfully!', '', 'success')
          this.jobpostForm.reset();
          this.getData(this.searchText);
          // $("#closeModel").click(); 
        } else {
          Swal.fire(result.errorDesc, '', 'error')
          //this.errorstr = result.errorDesc;
        }
      });
    }
  }

  create() {

    let updatedDeadLineData = this.datepipe.transform(this.jobpostForm.value.deadLine, 'yyyy-MM-dd h:mm:ss');
    this.jobpostForm.value.deadLine = updatedDeadLineData;


    if (this.jobpostForm.valid) {
      this.service.addjobpost(this.jobpostForm.value, this.type, (result: any) => {
        if (result.result == 'ok') {
          this.errorstr = '';
          Swal.fire('Record added successfully!', '', 'success')
          this.jobpostForm.reset();
          // $("#closeModel").click(); 
          this.getData(this.searchText);
        } else {
          Swal.fire(result.errorDesc, '', 'error')
          //this.errorstr = result.errorDesc;
        }
      });
    }
  }
  get f() {
    return this.jobpostForm.controls;
  }

  editJob(job: any, event) {
    event.stopPropagation();

    if (this.type.toUpperCase() != "COURSE") {
      this.store.dispatch(UserActions.setJobDetail(job));
    } else {
      this.store.dispatch(UserActions.setCourseDetail(job));
    }
    this.router.navigate(['/post-a-job'], { queryParams: { type: this.type, id : job.id } })

  }

  showJobPost(jobpost) {
    let flag = false;
    if (this.username) {
      //logined user
      flag = true;

    } else {

      if (this.type.toUpperCase() == 'COURSE') {
        flag = true;
      }
      else {
        if (jobpost.status == 'Approved') {
          flag = true;
        }
      }
    }

    return flag;
  }

  showJobPostData(jobPost: any) {
    this.service.setSelectedJobPost(jobPost);

    this.router.navigate(['job-details'], { queryParams: { type: this.type, id: jobPost.id, returnUrl: this.router.url } });

  }

  getFile(uuid: string) {
    if (this.fileMap.has(uuid)) {
      let fileName = this.fileMap.get(uuid);
      //fileName = fileName.replace('dataimage/jpegbase64', 'data:image/jpeg;base64,');
      return fileName;
    }

    return "./assets/img/home-1/jobs/1.png";

  }

  getLogo() {
    let data = "";
    try {
      data = this.userdetail.data.logo;
    } catch (error) {

    }

    return data;
  }

  isExpired(datetime: string): boolean {
    var ret = false;
    try {
      let dtObj = new Date(Number.parseInt(datetime?.substring(6,10)), Number.parseInt(datetime?.substring(3,5)) - 1, Number.parseInt(datetime?.substring(0,2)), Number.parseInt(datetime?.substring(11,13)), Number.parseInt(datetime?.substring(14,16)), Number.parseInt(datetime?.substring(17,19)));
      // console.log(dtObj?.toISOString());
      ret = dtObj?.getTime() < (new Date()).getTime();
    } catch (error) {
      ret = false;
      console.log(error);
    }
    // console.log(ret);
    return ret;

  }


}
