<div class="banner-area banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <!-- <div class="candidate-container mt-3">
                    <ngx-slick-carousel
                        class="carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig"
                        (afterChange)="afterChange($event)"
                    >
                        
                        <div ngxSlickItem class="slide" *ngFor="let item of candidatesList">
                            <div class="card" (click)="onCandidateClick(item)">
                                <img *ngIf="candidateImages[item?.uuid]" [src]="candidateImages[item?.uuid]" alt="" style="width: 250px; height: 230px;">
                                <img *ngIf="candidateImages[item?.uuid]=='' || !candidateImages[item?.uuid]" src="../../../../assets/img/No-Image-Placeholder.jpg" alt="" style="width: 250px; height: 230px;">
                                <div class="px-3 py-2">
                                    <h5 class="text-truncate">{{item.name}}</h5>
                                    <p>{{item.qualification}}</p>
                                </div>
                            </div>
                        </div>
                        
                    </ngx-slick-carousel>
                </div> -->

                <div class="banner-text">
                    <h1>Jobs <span>Without</span> Limit</h1>
                    <p>Jobs, Employment & Future Career Opportunities</p>

                    <div class="banner-form-area">
                        <form [formGroup]="searchForm">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-search-1"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Job Title"
                                            formControlName="name"
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="icofont-location-pin"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="City"
                                            formControlName="location"
                                        />
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4">
                                    <div class="form-group">
                                        <select>
                                            <option>Job Category</option>
                                            <option>Another option</option>
                                            <option>A option</option>
                                            <option>Potato</option>
                                        </select>	
                                    </div>
                                </div> -->
                            </div>

                            <button
                                type="submit"
                                class="btn banner-form-btn"
                                (click)="getData('')"
                            >
                                Search
                            </button>
                        </form>
                    </div>

                    <div class="banner-btn">
                        <a routerLink="/create-account">Register Now</a>
                        <!-- <a routerLink="/submit-resume">Upload Your CV</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="account-area">
    <div class="container">
        <div class="row account-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-approved"></i>
                    <span>Register Your Account</span>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-cv"></i>
                    <span>Upload Your Resume</span>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="account-item account-last">
                    <i class="flaticon-customer-service"></i>
                    <span>Apply for Dream Job</span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="category-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Desire Category</h2>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item">
                    <i class="flaticon-settings"></i>
                    <a routerLink="/">Technical Support</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-two">
                    <i class="flaticon-layers"></i>
                    <a routerLink="/">Business Development</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-three">
                    <i class="flaticon-house"></i>
                    <a routerLink="/">Real Estate Business</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-four">
                    <i class="flaticon-analysis"></i>
                    <a routerLink="/">Share Maeket Analysis</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-three">
                <div class="category-item category-five">
                    <i class="flaticon-sun"></i>
                    <a routerLink="/">Weather & Environment</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-six">
                    <i class="flaticon-hand"></i>
                    <a routerLink="/">Finance & Banking Service</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-seven">
                    <i class="flaticon-neural"></i>
                    <a routerLink="/">IT & Networing Sevices</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-eight">
                    <i class="flaticon-dish"></i>
                    <a routerLink="/">Restaurant Services</a>
                </div>
            </div>

            <div class="col-sm-3 offset-sm-3 offset-lg-0 col-lg-3 category-border-two">
                <div class="category-item category-nine">
                    <i class="icofont-fire-burn"></i>
                    <a routerLink="/">Defence  & Fire Service</a>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="category-item category-ten">
                    <i class="flaticon-truck"></i>
                    <a routerLink="/">Home Delivery Services</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="portal-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7">
                            <img src="./assets/img/home-1/1.jpg" alt="Portal">
                        </div>

                        <div class="col-lg-5">
                            <img src="./assets/img/home-1/2.jpg" alt="Portal">
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right">
                    <h2>Trusted & Popular Job Portal</h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur.</p>
                    <div class="common-btn">
                        <a class="login-btn" routerLink="/post-a-job">Post a Job <i class="icofont-swoosh-right"></i></a>
                        <a class="sign-up-btn" routerLink="/create-account">Apply Now <i class="icofont-swoosh-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="job-area pb-70 mt-4">
    <div class="container">
        <div class="section-title">
            <h2>Recent Jobs</h2>
        </div>

        <!-- <div class="sorting-menu">
            <ul> 
                <li class="filter active" data-filter="all">All</li>
                <li class="filter" data-filter=".web">New</li>
                <li class="filter" data-filter=".ui">Featured</li>
                <li class="filter" data-filter=".branding">Recent</li>
                <li class="filter" data-filter=".ux">Full Time</li>
                <li class="filter" data-filter=".wp">Part Time</li>
            </ul>
        </div> -->

        <div id="container">
            <div class="row">
                <div
                    class="col-lg-6 mix web ui"
                    *ngFor="let jobPost of jobPostList"
                >
                    <div class="job-item" (click)="showJobPostData(jobPost)">
                        <img
                            *ngIf="jobPost.hasCompanyLogo"
                            [src]="getFile(jobPost.companyUuid)"
                            [alt]="type"
                            width="65px"
                        />

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3 *ngIf="jobPost.categoryId">
                                    {{ jobPost.name }}
                                </h3>
                                <h3 *ngIf="!jobPost.categoryId">
                                    {{ jobPost.title }}
                                </h3>
                                <!-- <a href="{{jobPost.webLink}}">{{jobPost.webLink}}</a> -->
                                <ul>
                                    <li>
                                        <i class="icofont-hour-glass"></i>
                                        <span class="text-dark">Deadline:</span>
                                        <span *ngIf="jobPost.categoryId">
                                            {{ jobPost.deadLine }}</span
                                        >
                                        <span *ngIf="!jobPost.categoryId">
                                            {{ jobPost.expireDate }}</span
                                        >
                                    </li>
                                    <li>
                                        <i class="icofont-location-pin"></i>
                                        <span class="text-dark">Location:</span>
                                        <span> {{ jobPost.location }}</span>
                                    </li>
                                    <li>
                                        <i class="icofont-calendar"></i>
                                        <span class="text-dark"
                                            >CreatedAt:</span
                                        >
                                        <span *ngIf="jobPost.categoryId">
                                            {{ jobPost.createdAt }}</span
                                        >
                                        <span *ngIf="!jobPost.categoryId">
                                            {{ jobPost.postedAt }}</span
                                        >
                                    </li>
                                </ul>
                            </div>

                            <div
                                class="job-inner-right"
                                *ngIf="jobPost.categoryId"
                            >
                                <ul>
                                    <li>
                                        <span>{{ jobPost.categoryName }}</span>
                                    </li>
                                    <!-- <li><span style="background: gray !important;">{{jobPost.status}}</span></li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="row mt-3 pt-2" style="display: none">
                            <div class="col-md-12">
                                <div class="job-details-item">
                                    <div class="job-description">
                                        <h4 style="font-weight: 700">
                                            Description:
                                        </h4>
                                        <p>{{ jobPost.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-6 col-sm-4 col-lg-4">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">{{stats?.jobAvailableCount}}</span></h3>
                    <p>Job Available</p>
                </div>
            </div>

            <div class="col-6 col-sm-4 col-lg-4">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3><span class="counter">{{stats?.cvSubmittedCount}}</span></h3>
                    <p>CV Submitted</p>
                </div>
            </div>

            <div class="col-6 col-sm-4 col-lg-4">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3><span class="counter">{{stats?.companiesCount}}</span></h3>
                    <p>Companies</p>
                </div>
            </div>

            <!-- <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i
                        class="flaticon-businessman-paper-of-the-application-for-a-job"
                    ></i>
                    <h3><span class="counter">35</span>+</h3>
                    <p>Appointed to Job</p>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- <div class="popular-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <img src="./assets/img/home-1/3.jpg" alt="Popular">
                        </div>

                        <div class="col-lg-5">
                            <div class="practice-inner">
                                <img src="./assets/img/home-1/4.jpg" alt="Popular">
                                <img src="./assets/img/home-1/5.jpg" alt="Popular">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>Why We are Most Popular</h2>
                    </div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida</p>

                    <div class="row popular-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                <li><i class="flaticon-employee"></i> International Job</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="companies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Partners</h2>
        </div>

        <div class="companies-slider owl-theme owl-carousel">
            <div class="companies-item">
                <img
                    src="./assets/img/partners/GLogo-removebg-preview.png"
                    alt="Companies"
                />
                <h3><a href="javascript:void(0);">Golis</a></h3>
                <!-- <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p> -->
                <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
            </div>
            <div class="companies-item">
                <img
                    src="./assets/img/partners/Telesom_logo.png"
                    alt="Companies"
                />
                <h3><a href="javascript:void(0);">Telesom</a></h3>
                <!-- <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p> -->
                <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
            </div>
            <div class="companies-item">
                <img
                    src="./assets/img/partners/Hlogo-removebg-preview.png"
                    alt="Companies"
                />
                <h3><a href="javascript:void(0);">Hormuud</a></h3>
                <!-- <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p> -->
                <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
            </div>
            <div class="companies-item">
                <img
                    src="./assets/img/partners/Innoviilogo.png"
                    alt="Companies"
                />
                <h3><a href="javascript:void(0);">Innovii</a></h3>
                <!-- <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p> -->
                <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
            </div>
            <div class="companies-item">
                <img
                    src="./assets/img/partners/SOMALISMSlogo.png"
                    alt="Companies"
                />
                <h3><a href="javascript:void(0);">Somalisms</a></h3>
                <!-- <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p> -->
                <!-- <a class="companies-btn" routerLink="/create-account">Hiring</a> -->
            </div>
        </div>
    </div>
</section>

<!-- <section class="profile-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Featured Profile</h2>
        </div>

        <div class="profile-slider owl-theme owl-carousel">
            <div class="profile-item">
                <img src="./assets/img/home-1/profile/1.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/2.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/3.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="profile-item">
                <img src="./assets/img/home-1/profile/4.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/5.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/6.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/7.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/8.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/1.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/2.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/3.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="./assets/img/home-1/profile/4.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="app-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="app-item app-left">
                    <img src="./assets/img/home-1/6.png" alt="Mobile">
                    <img src="./assets/img/home-1/7.png" alt="Mobile">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="app-item">
                    <div class="section-title text-start">
                        <h2>Download  The Glabe Mobile App</h2>
                    </div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    <div class="app-btn">
                        <a class="app-btn-one" href="#">
                            <i class="flaticon-apple"></i>
                            <span>Download on the</span>
                            <p>App Store</p>
                        </a>
                        <a class="app-btn-two" href="#">
                            <i class="flaticon-playstore"></i>
                            <span>ANDROID APP ON</span>
                            <p>Google Play</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="blog-area pt-100 pb-170">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="./assets/img/home-1/blog/1.jpg" alt="Blog"></a>
                        <span>21 May, 2020</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <ul>
                            <li><img src="./assets/img/home-1/blog/1.png" alt="Blog"> Aikin Ward</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".4s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="./assets/img/home-1/blog/2.jpg" alt="Blog"></a>
                        <span>22 May, 2020</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">It is the most important sector in the world</a></h3>
                        <ul>
                            <li><img src="./assets/img/home-1/blog/1.png" alt="Blog">Aikin Ward</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".5s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="./assets/img/home-1/blog/3.jpg" alt="Blog"></a>
                        <span>23 May, 2020</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Nowadays IT is being most popular</a></h3>
                        <ul>
                            <li><img src="./assets/img/home-1/blog/1.png" alt="Blog"> Aikin Ward</li>
                            <li><a routerLink="/blog-details"> Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
