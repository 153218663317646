import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { LOCAL_STORAGE_KEYS } from 'src/app/helpers/Enums';
import * as UserActions from 'src/app/store/app.actions';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  credentials = { msisdn: '', password: '', otp: '' };
  errorstr: string | undefined;
  country_code = '+252';
  showOtpPage: boolean = false;
  buttontext = 'Sign In';
  isLoggedIn: boolean = false;
  username: string;

  constructor(private app: AppService, private http: HttpClient, private router: Router, @Inject(AppStore) public store: Store<AppState>) {
    store.subscribe(() => this.updateState());
  }

  updateState() {
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;
  }

  ngOnInit(): void {
    this.updateState();

    if (this.isLoggedIn) {
      this.router.navigate(['']);
    }
  }

  login() {
    // this.router.navigateByUrl('dashboard');
    if (!this.showOtpPage) {
      if (this.credentials.msisdn != '' && this.credentials.password != '') {
        this.errorstr = '';
        this.app.authenticate(this.credentials, (result: any, debugInfo: any) => {
          if (result == false) {
            this.errorstr = debugInfo;
          } else {
            //this.router.navigateByUrl('job-list');
            this.showOtpPage = true;
            this.buttontext = "Submit";
          }
        });
      }
    } else {

      if (this.credentials.msisdn != '' && this.credentials.otp != '') {
        this.errorstr = '';
        this.app.authenticateconfirm(this.credentials, (result: any, debugInfo: any) => {
          if (result == false) {
            this.errorstr = debugInfo;
          } else {
            console.log("Result: ", debugInfo);
            this.store.dispatch(UserActions.setIsLoggedIn(true));

            setTimeout(() => {
              this.app.getCompanyProfile((data) => {

                this.store.dispatch(UserActions.setCurrentUserDetails(data));
                this.router.navigateByUrl('job-list');
              });
            }, 500);

            //this.showOtpPage = true;
          }
        });
      }
    }
    return false;
  }


}
