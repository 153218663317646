export const Messages = {
    SESSIONEND: "SESSION_END",
    TOKENPRESENT: "TOKEN_PRESENT"
}

export const API = {
    REAUTHENTICATE: "login/user",
    AUTHENTICATE: "sign-in",
    AUTHENTICATECONFIRM: "sign-in/confirm",

    COMPANYCATGEORIES:"company/category/list",
    PROFILE : "company/profile",
    PROFILESAVE : "company/profile/edit",


    ADDJOBPOST:"company/position/create",
    JOBPOST:"company/position/list",
    DELETEJOB:"company/position/delete",
    UPDATEJOBPOST:"company/position/edit",
    JOBFILE : "company/position/file",
    JOBIMAGEFILE : "company/position/file/",
    ADMINJOBLIST : "content/viewer/position/list",
    ADMINJOBIMAGEFILE :"content/viewer/position/file/",

    ADDTENDER:"company/tender/create",
    LISTTENDER:"company/tender/list",
    DELETETENDER:"company/tender/delete",
    UPDATETENDER:"company/tender/edit",
    TENDERFILE : "company/tender/file",
    ADMINTENDERLIST : "content/viewer/tender/list",
    ADMINTENDERIMAGEFILE :"content/viewer/tender/file/",
    COMPANYLOGO: "content/viewer/company/logo/",

    ADDCOURSE:"company/course/create",
    LISTCOURSE:"company/course/list",
    DELETECOURSE:"company/course/delete",
    UPDATECOURSE:"company/course/edit",
    COURSEFILE : "company/course/file/",
    ADMINCOURSELIST : "content/viewer/course/list",
    ADMINCOURSEIMAGEFILE :"content/viewer/course/file/",

    SIGNUP : "sign-up",
    CONFIRMSIGNUP : "sign-up/confirm",
    OTPRESEND : "otp/resend",
    LOGOUT : "company/logout",

    // Candidate
    CREATECANDIDATE: "candidate/create",
    VIEWCANDIDATES: "candidate/list",
    FETCHCANDIDATEIMAGE: "candidate/file/picture/",
    FETCHCANDIDATECV: "candidate/file/cv/",

    // Home Page
    VIEWDASHBOARDSTATS: "content/viewer/dashboard",

}
