<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>{{type}} List</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>{{type}} List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-two pt-100">
    <div class="container">
        <div class="banner-form-area mb-1 mt-0 pt-0">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div [class]="!isLoggedIn || (type != 'Job' ) ? 'col-lg-6' : 'col-lg-4'">
                        <div class="form-group">
                            <label><i class="icofont-search-1"></i></label>
                            <input type="text" class="form-control" formControlName="name" placeholder="{{type}} Title">
                        </div>
                    </div>

                    <div [class]="!isLoggedIn || (type != 'Job' )? 'col-lg-6' : 'col-lg-4'">
                        <div class="form-group">
                            <label><i class="icofont-location-pin"></i></label>
                            <input type="text" class="form-control" placeholder="City"
                                formControlName="location">
                        </div>
                    </div>

                    <div *ngIf="isLoggedIn && (type == 'Job' )" class="col-lg-4">
                        <div class="form-group">
                            <select formControlName="categoryId" nice-select>
                                <option value="" disabled selected>{{type}} Category</option>
                                <option *ngFor="let category of categories" [ngValue]="category['id']">
                                    {{ category['name'] }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <button type="submit" class="btn banner-form-btn" (click)="onSearch()">Search</button>

            </form>
        </div>

        <div class="row">
            <div class="col-lg-6" *ngFor="let jobPost of jobPostList">
                <div class="job-item" *ngIf="showJobPost(jobPost)" (click)="showJobPostData(jobPost)">
                    <img  *ngIf="jobPost.hasCompanyLogo" src="{{getFile(jobPost.companyUuid)}}" alt="{{type}}" width="65px">
    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3 *ngIf="jobPost.categoryId">{{jobPost.name}}</h3>
                            <h3 *ngIf="!jobPost.categoryId">{{jobPost.title}}</h3>
                            <!-- <a href="{{jobPost.webLink}}">{{jobPost.webLink}}</a> -->
                            <ul>
                                
                                <li>
                                    <i class="icofont-hour-glass"></i>
                                    <span class="text-dark">Deadline:</span>
                                    <span *ngIf="jobPost.categoryId"> {{jobPost.deadLine}}</span>
                                    <span *ngIf="!jobPost.categoryId"> {{jobPost.expireDate}}</span>
                                </li>
                                <li>
                                    <i class="icofont-location-pin"></i>
                                    <span class="text-dark">Location:</span>
                                    <span> {{jobPost.location}}</span>
                                </li>
                                <li>
                                    <i class="icofont-calendar"></i>
                                    <span class="text-dark">CreatedAt:</span>
                                    <span *ngIf="jobPost.categoryId"> {{jobPost.createdAt}}</span>
                                    <span *ngIf="!jobPost.categoryId"> {{jobPost.postedAt}}</span>
                                </li>
                            </ul>
                        </div>

                        <div class="job-inner-right" *ngIf="jobPost.categoryId">
                            <ul>
                                <li><span>{{jobPost.categoryName}}</span></li>
                                <li><span style="background: gray !important;">{{jobPost.status}}</span></li>
                            </ul>
                        </div>
                        
                    </div>
                    <!-- <div class="row mt-3 pt-2">
                        <div class="col-md-12">
                            <div class="job-details-item">
                                <div class="job-description">
                                    <h4 style="font-weight: 700;">Description:</h4>
                                    <p> {{jobPost.description}}</p>
                                </div>
                            </div>
                        
                        
                        </div>
                    </div> -->
                    <div class="row pt-2" *ngIf="username && jobPost.status != 'Approved' && type !== 'Course'">
                        <div class="col-md-12 job-inner">
                            <div class="job-inner-left"></div>
                            <div class="job-inner-right">
                                <ul style="float: right;">
                                    <li style="cursor: pointer;" (click)="editJob(jobPost, $event)"><span>Edit</span></li>
                                    <li style="cursor: pointer;" (click)="onDelete(jobPost, $event)"><span style="background: gray !important;">Delete</span></li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                    
                </div>
                
                
            </div>

            <!--     <div class="col-lg-6">
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/2.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Android Developer</h3>
                            <a routerLink="/company-details">Infiniza.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

             <div class="col-lg-6"> 
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/3.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Senior Manager</h3>
                            <a routerLink="/company-details">Glovibo.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Intern</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item ">
                    <img src="./assets/img/home-1/jobs/4.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Product Designer</h3>
                            <a routerLink="/company-details">Bizotic.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/5.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Digital Marketer</h3>
                            <a routerLink="/company-details">Hotelzo.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Intern</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/6.png" alt="{{type}}">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Sales Manager</h3>
                            <a routerLink="/company-details">Gozuto.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/7.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Web Developer</h3>
                            <a routerLink="/company-details">Udiza.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Full Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item">
                    <img src="./assets/img/home-1/jobs/8.png" alt="{{type}}">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>SEO</h3>
                            <a routerLink="/company-details">Oqota.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</section>