<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Log In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Log In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area pt-100">
    <div class="container">
        <!-- <h2>Login Your Account</h2> -->

        <div class="login-wrap">
            <div class="row">
                <!-- <div class="col-sm-6 col-lg-6">
                    <div class="jobseeker-item">
                        <div class="jobseeker-icon">
                            <i class="flaticon-job-search"></i>
                        </div>
                        
                        <div class="jobseeker-inner">
                            <span>Candidate</span>
                            <h3>Login as a Candidate</h3>
                        </div>

                        <a routerLink="/login">Get Started<i class="icofont-arrow-right"></i></a>
                    </div>
                </div> -->

                <div class="col-sm-6 col-lg-6">
                    <div class="jobseeker-item">
                        <div class="jobseeker-icon">
                            <i class="flaticon-recruitment"></i>
                        </div>

                        <div class="jobseeker-inner">
                            <!-- <span>Employer</span> -->
                            <h3>Login to Post Jobs or Tenders</h3>
                        </div>

                        <!-- <a routerLink="/login">Get Started<i class="icofont-arrow-right"></i></a> -->
                    </div>
                </div>
            </div>
        </div>

        <form role="form">
            <div *ngIf="errorstr" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{ errorstr }}
            </div>
            <div class="form-group" *ngIf="!showOtpPage">
                <!-- <input type="text" class="form-control" placeholder="Username"> -->
                <input type="text" class="form-control" placeholder="Phone Number" id="msisdn" appForbiddenName="bob"
                    name="msisdn" required minlength="9" [(ngModel)]="credentials.msisdn" #msisdn="ngModel" />
                <div *ngIf="msisdn.invalid && (msisdn.dirty || msisdn.touched)" class="alert alert-warning">

                    <div *ngIf="msisdn.errors?.['required']">
                        msisdn is required.
                    </div>
                    <div *ngIf="msisdn.errors?.['minlength']">
                        msisdn must be at least 9 characters long.
                    </div>
                    <div *ngIf="msisdn.errors?.['forbiddenName']">
                        msisdn cannot be Bob.
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="!showOtpPage">
                <!-- <input type="password" class="form-control" placeholder="Password"> -->
                <input type="password" class="form-control" placeholder="Password" id="password" name="password"
                    required [(ngModel)]="credentials.password" #password="ngModel" />
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-warning">
                    <div *ngIf="password.errors?.['required']">
                        password is required.
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="showOtpPage">
                <!-- <label>Enter Otp</label> -->
                <input type="text" id="otp" name="otp" class="form-control" placeholder="Enter OTP" required [(ngModel)]="credentials.otp" #otp="ngModel" minlength="6" maxlength="6" >
                <div class="alert alert-warning"
                    *ngIf="otp && otp.errors && otp.errors.required  && (otp.dirty || otp.touched)">
                    otp is required</div>
                <div class="alert alert-warning"
                    *ngIf="otp && otp.errors && otp.errors.minlength && (otp.dirty || otp.touched)">
                    otp must 6 characters</div>
            </div>



        <div class="login-sign-in">
            <!-- <a routerLink="/">Forgot Password?</a> -->

            <ul>
                <li>Don’t Have Account ?</li>
                <li><a routerLink="/create-account">Sign Up Here</a></li>
            </ul>

            <div class="text-center">
                <button name="submit" type="submit" class="btn login-btn" id="login" (click)="login()">{{buttontext}}</button>
            </div>
        </div>
    </form>
        <!-- <div class="login-social">
            <a href="#"><i class="icofont-facebook"></i> Login With Facebook</a>
            <a class="login-google" href="#"><i class="icofont-google-plus"></i> Login With Google</a>
        </div> -->
    </div>
</div>