<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Create Candidate</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Create Candidate</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="create-account-area pt-100 pb-100">
    <div class="container">
        <form [formGroup]="createCandidate">
            <!-- <div class="create-photo">
                <div class="already-create">
                    <span>Already create an account?</span>
                    <a routerLink="/login">Sign In</a>
                </div>


                <div class="row align-items-center">

                    <div class="col-lg-4">
                        <div class="create-photo-item">
                            <div class="create-photo-left">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="form-group">
                                            <i class="icofont-photobucket"></i>
                                            <label for="inputField" class="btn btn-info">{{filelabel}}</label>
                                            <input type="file" id="inputField" class="form-control-file"
                                                formControlName="logo" placeholder="Choose Logo" style="display:none"
                                                (change)="onFileChange($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="create-photo-item">
                            <div class="create-photo-right">
                                <div class="form-group">
                                    <input type="text" class="form-control" minlength="12" maxlength="12"
                                        pattern="{{mobileNumberPattern}}" formControlName="msisdn"
                                        placeholder="Phone Number">
                                    <div class="alert alert-warning"
                                        *ngIf="msisdn && msisdn.invalid && (msisdn.dirty || msisdn.touched)">
                                        <div *ngIf="msisdn.errors.pattern">Mobile Number should start with
                                            {{mobileNumberPrefix}}
                                        </div>
                                        <div *ngIf="msisdn.errors.required">Mobile Number is required</div>
                                        <div *ngIf="msisdn.errors.minlength || msisdn.errors.maxlength">Mobile
                                            Number must be of 12 characters
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password">
                                    <div class="alert alert-warning"
                                        *ngIf="password && password.errors && password.errors.required && (password.dirty || password.touched)">
                                        Password is required</div>
                                    <div class="alert alert-warning"
                                        *ngIf="password && password.errors && password.errors.minlength && (password.dirty || password.touched)">
                                        Password must be at least 6 characters</div>
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Confirm Password"
                                        formControlName="confirmPassword">
                                    <div class="alert alert-warning"
                                        *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.required && (confirmPassword.dirty || confirmPassword.touched)">
                                        Confirm Password is required</div>
                                    <div class="alert alert-warning"
                                        *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors.mustMatch && (confirmPassword.dirty || confirmPassword.touched)">
                                        Passwords must match</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="create-information">
                <div class="row">
                    <div class="col-lg-12 d-flex justify-content-end">
                        <div class="form-group">
                            <img
                                *ngIf="profileFile"
                                [src]="profileFile"
                                class="profileImg"
                                alt="profile picture"
                            />
                            <div class="mt-2">
                                <label for="picture" class="btn-custom"
                                    >Profile Picture</label
                                >
                                <input
                                    type="file"
                                    id="picture"
                                    class="form-control-file"
                                    placeholder="Upload Picture"
                                    accept="image/*"
                                    style="display: none"
                                    (change)="onFileChange($event, 'profile')"
                                    formControlName="picture"
                                />
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>First Name:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="firstName"
                            />
                            <div
                                class="alert alert-warning"
                                *ngIf="
                                    firstName &&
                                    firstName.errors &&
                                    firstName.errors.required &&
                                    (firstName.dirty || firstName.touched)
                                "
                            >
                                First Name is required
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Last Name:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="lastName"
                            />
                            <div
                                class="alert alert-warning"
                                *ngIf="
                                    lastName &&
                                    lastName.errors &&
                                    lastName.errors.required &&
                                    (lastName.dirty || lastName.touched)
                                "
                            >
                                Last Name is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-lg-6">
                        <label>Mobile Number:</label>
                        <input
                            type="text"
                            class="form-control"
                            minlength="12"
                            maxlength="12"
                            pattern="{{ mobileNumberPattern }}"
                            formControlName="msisdn"
                            placeholder=""
                        />
                        <div
                            class="alert alert-warning"
                            *ngIf="
                                msisdn &&
                                msisdn.invalid &&
                                (msisdn.dirty || msisdn.touched)
                            "
                        >
                            <div *ngIf="msisdn.errors.pattern">
                                Mobile Number should start with
                                {{ mobileNumberPrefix }}
                            </div>
                            <div *ngIf="msisdn.errors.required">
                                Mobile Number is required
                            </div>
                            <div
                                *ngIf="
                                    msisdn.errors.minlength ||
                                    msisdn.errors.maxlength
                                "
                            >
                                Mobile Number must be of 12 characters
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Location:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="address"
                            />
                            <div
                                class="alert alert-warning"
                                *ngIf="
                                    address &&
                                    address.invalid &&
                                    (address.dirty || address.touched)
                                "
                            >
                                <div *ngIf="address.errors.required">
                                    Location is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Qualification:</label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="qualification"
                            />
                            <div
                                class="alert alert-warning"
                                *ngIf="
                                    qualification &&
                                    qualification.invalid &&
                                    (qualification.dirty || qualification.touched)
                                "
                            >
                                <div *ngIf="address.errors.required">
                                    Qualification is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group mt-4">
                            <label for="inputField" class="btn-custom">{{
                                filelabel
                            }}</label>
                            <input
                                type="file"
                                id="inputField"
                                class="form-control-file"
                                placeholder="Upload Doc"
                                accept=".pdf"
                                style="display: none"
                                (change)="onFileChange($event, 'document')"
                                formControlName="document"
                            />
                            <p *ngIf="docFileName" class="text-truncate">{{docFileName}}</p>
                            <div
                                class="alert alert-warning"
                                *ngIf="
                                    document &&
                                    document.errors &&
                                    document.errors.required &&
                                    (document.dirty || document.touched)
                                "
                            >
                                Document is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-left">
                <button
                    type="submit"
                    class="btn create-ac-btn"
                    (click)="onSubmit()"
                >
                    Save
                </button>
                <button
                    type="submit"
                    class="btn create-blue-btn mx-3"
                    (click)="setDefaultValues()"
                >
                    Reset
                </button>
            </div>
        </form>
    </div>
</div>
