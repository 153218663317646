import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState, Course, JobDetail } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import Swal from 'sweetalert2';
import * as UserActions from 'src/app/store/app.actions';
import { type } from 'os';
import { read } from 'fs';
import { Editor, Toolbar, toHTML } from 'ngx-editor';

@Component({
  selector: 'app-post-a-job',
  templateUrl: './post-a-job.component.html',
  styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit, OnDestroy {

  categories = [{ "id": 1, "name": "UI" }];
  jobForm: FormGroup;
  errorstr: string = '';
  selfile: any;
  type: string;
  jobDetail: JobDetail;
  courseDetail: Course;
  isLoggedIn: boolean = false;
  username: string;
  editor: Editor;
  html: string = '';
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(private service: AppService, private route: ActivatedRoute, @Inject(AppStore) public store: Store<AppState>,
    private router: Router) {
    store.subscribe(() => this.updateState());
  }

  updateState() {
    this.isLoggedIn = this.store.getState().isLoggedIn;
    this.username = this.store.getState().username;

    if (this.type.toUpperCase() != "COURSE") {
      this.jobDetail = this.store.getState().jobDetail;
      console.log("Job Detail: ", this.jobDetail);
    } else {
      this.courseDetail = this.store.getState().courseDetail;
      console.log("Course Detail: ", this.courseDetail);
    }
  }

  ngOnInit(): void {
    this.editor = new Editor();

    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { order: "popular" }

        this.type = params.type;
        if (this.type == null || this.type == undefined) {
          this.type = 'Job';
        }


        this.updateState();

        if (!this.username) {
          this.router.navigate(['/login']);
        }
        console.log(this.username);


        this.initializeJobPostForm();

        this.getCompanyCategories();

        this.setDefaultValues();
        this.populateForm();

        console.log(this.type); // popular
      }
      );


  }

  private populateForm() {
    if (this.type.toUpperCase() != "COURSE") {
      if (this.jobDetail == null || this.jobDetail == undefined) return;
      this.jobForm.reset();
      this.jobForm.patchValue({
        id: this.jobDetail.id,
        categoryId: this.jobDetail.categoryId,
        deadLine: convertDateFormat(this.jobDetail.deadLine),
        description: this.jobDetail.description,
        location: this.jobDetail.location,
        name: this.jobDetail.name,
        webLink: this.jobDetail.webLink,
        applyLink : this.jobDetail.applyLink
      });
    } else {
      if (this.courseDetail == null || this.courseDetail == undefined) return;
      this.jobForm.reset();
      this.jobForm.patchValue({
        id: this.courseDetail.id,
        categoryId: this.courseDetail.title,
        deadLine: convertDateFormat(this.courseDetail.expireDate),
        description: this.courseDetail.description,
        location: this.courseDetail.location,
        name: this.courseDetail.title,
        applyLink : this.courseDetail.applyLink
      });
    }
  }

  private initializeJobPostForm() {
    this.jobForm = new FormGroup({
      id: new FormControl(null),
      categoryId: new FormControl(''),
      deadLine: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      file: new FormControl(''),
      fileName: new FormControl(''),
      location: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      webLink: new FormControl('', [Validators.required]),
      qualification: new FormControl(null),
      applyLink : new FormControl('')
    });
  }

  private getCompanyCategories() {
    this.service.getCompanyCategories(0, 25, (result: any) => {
      this.categories = result.data;
    });
  }

  get id() { return this.jobForm.get('id'); }
  get categoryId() { return this.jobForm.get('categoryId'); }
  get deadLine() { return this.jobForm.get('deadLine'); }
  get description() { return this.jobForm.get('description'); }
  get file() { return this.jobForm.get('file'); }
  get fileName() { return this.jobForm.get('fileName'); }
  get location() { return this.jobForm.get('location'); }
  get name() { return this.jobForm.get('name'); }
  get webLink() { return this.jobForm.get('webLink'); }
  get applyLink() { return this.jobForm.get('applyLink'); }

  setDefaultValues() {
    this.jobForm.reset();

    this.jobForm.patchValue({
      categoryId: this.categories.length > 0 ? this.categories[0].id : '',
      fileName: "sample.pdf"
    });

  }

  formatdatetime(): string {
    let deadLinevalue = this.deadLine.value;
    console.log("Deadline date: ", deadLinevalue);
    let newDeadLine = deadLinevalue.replace('T', ' ');
    console.log("After convert: ", newDeadLine, ' and length = ', newDeadLine.length);
    console.log("Submitting date: ", newDeadLine.length >= 19 ? newDeadLine : newDeadLine + ':00');
    return newDeadLine.length >= 19 ? newDeadLine : newDeadLine + ':00';
  }

  onSubmit() {
    if (this.jobDetail != null || !this.id) {
      this.onUpdate();
      this.store.dispatch(UserActions.setCourseDetail(null));
      this.store.dispatch(UserActions.setJobDetail(null));
      return;
    }
    if (this.jobForm.valid) {
      this.jobForm.patchValue({
        deadLine: this.formatdatetime()
      });

      let requestjson = this.jobForm.value;
      requestjson.description = toHTML(this.description.value);

      this.service.addjobpost(requestjson, this.type.toUpperCase(), (result) => {
        if (result.result === 'ok') {
          Swal.fire('Success', this.type + ' created successfully', 'success');
          this.jobForm.reset();
        }
        else {
          Swal.fire('Error', result.debugInfo, 'error');
        }
      });
    } else {
      this.jobForm.markAllAsTouched();
    }
  }

  handleFileInput(event) {
    const file1 = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file1);
    reader.onload = () => {
      //console.log(reader.result);
      let finalstring: any = reader.result;
      //finalstring = finalstring.split(',')[1];

      console.log(finalstring);

      this.jobForm.patchValue({
        fileName: file1.name,
        file: finalstring
      });

      let filename1 = this.fileName.value;
    };
  }

  onUpdate() {
    if (this.jobForm.valid) {
      this.jobForm.patchValue({
        id: this.jobDetail.id,
        deadLine: this.formatdatetime()
      });
      console.log("Sending data to backend: ", this.jobForm.value);
      this.service.updatejobpost(this.jobForm.value, this.type.toUpperCase(), (result) => {
        if (result.result === 'ok') {
          Swal.fire('Success', this.type + ' updated successfully', 'success');
          this.router.navigate(['/job-list'], { queryParams: { type: this.type } });
        }
        else {
          Swal.fire('Error', result.debugInfo, 'error');
        }
      });
    } else {
      this.jobForm.markAllAsTouched();
    }
  }

   // make sure to destory the editor
   ngOnDestroy(): void {
    this.editor.destroy();
  }


}

function convertDateFormat(inputDate: string): string {
  const dateParts = inputDate.split(' ');
  const date = dateParts[0];
  const time = dateParts[1];

  const dateParts2 = date.split('-');
  const year = dateParts2[2];
  const month = dateParts2[1];
  const day = dateParts2[0];

  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]) + 7; // Add 7 hours to convert to GMT+7 timezone
  const minute = timeParts[1];
  const second = timeParts[2];

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

