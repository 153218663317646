<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Company List</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Company List</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="companies-area companies-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="./assets/img/home-1/companies/1.png" alt="Companies">
                    <h3><a routerLink="/company-details">Winbrans.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Quadra, Street, Canada</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="./assets/img/home-1/companies/2.png" alt="Companies">
                    <h3><a routerLink="/company-details">Infiniza.com</a></h3>
                    <p><i class="icofont-location-pin"></i> North Street, California</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="./assets/img/home-1/companies/3.png" alt="Companies">
                    <h3><a routerLink="/company-details">Glovibo.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="./assets/img/home-1/companies/4.png" alt="Companies">
                    <h3><a routerLink="/company-details">Bizotic.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Washington, New York</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="./assets/img/home-1/companies/5.png" alt="Companies">
                    <h3><a routerLink="/company-details">Bethpage.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Las, Street, Canada</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="./assets/img/home-1/companies/6.png" alt="Companies">
                    <h3><a routerLink="/company-details">Hofstra.com</a></h3>
                    <p><i class="icofont-location-pin"></i> South Street, London</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="./assets/img/home-1/companies/7.png" alt="Companies">
                    <h3><a routerLink="/company-details">Mount.com</a></h3>
                    <p><i class="icofont-location-pin"></i> West Road, Cardiff</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="companies-item wow fadeInUp" data-wow-delay=".6s">
                    <img src="./assets/img/home-1/companies/8.png" alt="Companies">
                    <h3><a routerLink="/company-details">Protiviti.com</a></h3>
                    <p><i class="icofont-location-pin"></i> New DC, America</p>
                    <a class="companies-btn" routerLink="/create-account">Hiring</a>
                </div>
            </div>
        </div>
    </div>
</section>