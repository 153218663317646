<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Blog Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-details-item">
                    <div class="blog-details-img">
                        <img src="./assets/img/blod-details/1.jpg" alt="Blog Details">
                        <h3>When you create your resume, you may wonder if it’s acceptable to change the job title of a position that you held, or maybe you’re thinking about expanding the amount of time you held the job.</h3>

                        <ul>
                            <li><i class="icofont-calendar"></i>26 May, 2020</li>
                            <li><i class="icofont-user-alt-7"></i><a routerLink="/blog">Aikin Ward</a></li>
                        </ul>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>

                    <div class="blog-details-social">
                        <span>Share:</span>

                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="blog-details-nav">
                        <div class="previous-left">
                            <a routerLink="/blog-details">Previous</a>
                        </div>

                        <div class="previous-right">
                            <a routerLink="/blog-details">Next</a>
                        </div>
                    </div>

                    <div class="blog-details-form">
                        <h3>Drop Your Comment</h3>

                        <form id="contactForm">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                            </div>

                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                            </div>

                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                            </div>

                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                            </div>

                            <div class="text-left">
                                <button type="submit" class="btn blog-details-btn">Post Your Comment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-details-item">
                    <div class="single-resume-category">
                        <h3>Job Categories</h3>

                        <ul>
                            <li><a routerLink="/">Web Developer</a><span>(5)</span></li>
                            <li><a routerLink="/">Web Designer</a><span>(2)</span></li>
                            <li><a routerLink="/">UX/UI Designer</a><span>(7)</span></li>
                            <li><a routerLink="/">Marketing</a><span>(0)</span></li>
                            <li><a routerLink="/">SEO</a><span>(1)</span></li>
                            <li><a routerLink="/">Networking</a><span>(0)</span></li>
                        </ul>
                    </div>

                    <div class="single-resume-category">
                        <h3>Job Locations</h3>

                        <ul>
                            <li><a routerLink="/">New York</a><span>(4)</span></li>
                            <li><a routerLink="/">Washington</a><span>(5)</span></li>
                            <li><a routerLink="/">Chicago</a><span>(3)</span></li>
                            <li><a routerLink="/">Houston</a><span>(1)</span></li>
                            <li><a routerLink="/">Los Angeles</a><span>(0)</span></li>
                        </ul>
                    </div>

                    <div class="single-resume-category single-resume-types">
                        <h3>Job Types</h3>

                        <ul>
                            <li><a routerLink="/">Freelance</a><span>(11)</span></li>
                            <li><a routerLink="/">Part Time</a><span>(9)</span></li>
                            <li><a routerLink="/">Full Time</a><span>(7)</span></li>
                            <li><a routerLink="/">Internship</a><span>(13)</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>