import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from 'redux';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/store/app.state';
import { AppStore } from 'src/app/store/app.store';
import * as UserActions from '../../../store/app.actions';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  slides = [];
  currentSlide: number = 0;
  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  isLoggedIn: boolean = false;
  username: string;
  searchForm: FormGroup;
  searchText: any;
  categories = [];
  jobPostList: any[] = [];
  type: string = "Job";
  fileMap: Map<string, string> = new Map();
  userdetail: any;

  candidatesList: any = [];

  pagination = {
    start: 0,
    length: 10
  }
  total: number;
  isNext: boolean = true;

  constructor(private service: AppService, private router: Router, @Inject(AppStore) public store: Store<AppState>) {
    store.subscribe(() => this.updateState());
  }

  updateState() {
    try {

      this.isLoggedIn = this.store.getState().isLoggedIn;
      this.username = this.store.getState().username;
      this.userdetail = this.store.getState().currentuserdetail;

      // this.getCandidateList();
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      name: new FormControl(),
      location: new FormControl(),
    });
    
    this.getDashboradStats();
    this.updateState();

    this.loadNewCategoryComponent();
  }

  private loadNewCategoryComponent() {
    this.getData(this.searchText);
    // this.service.getCompanyCategories(0, 25, (result: any) => {
    //   this.categories = result.data;
    // });
  }

  stats: any;
  getDashboradStats(){
    try {
      this.service.getDashboardStats((res)=>{
        if(res.result==="ok"){
          this.stats = res.data;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  getCandidateList(){
    try {
      this.service.getCandidateList(this.pagination, (res)=>{      
        if(res.result==='ok'){
          this.candidatesList.push(...res.data);          
          this.total = res.total;

          if(res.data.length<1){            
            this.isNext = false;
          }

          this.fetchProfileImages(this.candidatesList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  candidateImages: any = {};
  fetchProfileImages(list){
    try {
      if(list.length>0){
        list.forEach(element => {
          this.service.fetchProfileImages(element.uuid, (res)=>{
            if(res.data.file){
              this.candidateImages[element.uuid] = "data:image/jpeg;base64," + res.data.file;
            }            
          })
        });
      }      
    } catch (error) {
      console.log(error);
    }
  }

  curSlide: number;
  afterChange(event){
    this.curSlide = event.currentSlide;
    if(((this.curSlide + 1) == this.total) && this.isNext){
      this.pagination.start += 11; 
      this.getCandidateList();
    }
  }

  onCandidateClick(item: any){
    this.store.dispatch(UserActions.setSelectedCandidate(item));
    this.router.navigateByUrl('/candidate-details');
  }

  getData(searchText: any) {

    this.service.getjobPostList(this.searchForm.value, this.type.toUpperCase(), (result: any) => {
      this.jobPostList = [];
      this.jobPostList = result.data;
      if(this.jobPostList?.length>0){
        this.jobPostList = this.jobPostList?.filter((ele: any) => ((ele.status.toUpperCase() === "APPROVED") && (!this.isExpired(ele.deadLine))));
        this.fileMap.clear();
        if(this.jobPostList?.length>0){
          for (let i = 0; i < this.jobPostList.length; i++) {
            let ele = this.jobPostList[i];
            if (ele.hasCompanyLogo && !this.fileMap.has(ele.companyUuid)) {
              this.service.getCompanyLogo(ele.companyUuid, (response) => {
                if (response != null && response != undefined && response) {
                  this.fileMap.set(ele.companyUuid, response.data.file);
                }
              });
            }
          }
        }
     
      }
    });
  }
  

  isExpired(datetime: string): boolean {
    var ret = false;
    try {
      let dtObj = new Date(Number.parseInt(datetime.substring(6,10)), Number.parseInt(datetime.substring(3,5)) - 1, Number.parseInt(datetime.substring(0,2)), Number.parseInt(datetime.substring(11,13)), Number.parseInt(datetime.substring(14,16)), Number.parseInt(datetime.substring(17,19)));
      // console.log(dtObj.toISOString());
      ret = dtObj.getTime() < (new Date()).getTime();
    } catch (error) {
      ret = false;
      console.log(error);
    }
    console.log(ret);
    return ret;

  }

  getFile(uuid: string) {
    if (this.fileMap.has(uuid)) {
      let fileName = this.fileMap.get(uuid);
      //fileName = fileName.replace('dataimage/jpegbase64', 'data:image/jpeg;base64,');
      return fileName;
    }

    return "./assets/img/home-1/jobs/1.png";

  }

  showJobPostData(jobPost: any) {
    this.service.setSelectedJobPost(jobPost);

    this.router.navigate(['job-details'], { queryParams: { type: this.type, returnUrl: this.router.url, id: jobPost.id } });

  }

  getLogo() {
    let data = "";
    try {
      data = this.userdetail.data.logo;
    } catch (error) {

    }

    return data;
  }

}
