import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppStore } from './store/app.store';
import { AppState } from './store/app.state';
import * as CONSTANTS from './helpers/constants'
import * as UserActions from 'src/app/store/app.actions';
import { LOCAL_STORAGE_KEYS } from './helpers/Enums';
import { AppConfig } from './app.config';
import { Store } from 'redux';
import { environment } from 'src/environments/environment.prod';


interface Response {
    result: string,
    error: string
}

@Injectable()
export class AppService {
    getDashboardContent(arg0: (result: import("./store/app.state").DashboardDto) => void) {
        throw new Error('Method not implemented.');
    }

    private BASEURL: string = "";
    isLoggedIn!: boolean;

    constructor(private http: HttpClient, @Inject(AppStore) public store: Store<AppState>, private appconfig: AppConfig) {
        try {
            this.store.subscribe(() => this.updateState());

            //this.store.dispatch(UserActions.setCurrentUserDetails(null));

        } catch (error) {
            throw new Error("Authguard::contructor Exception :" + error);

        }
    }

    updateState() {
        try {
            //this.currentuserdetail = this.store.getState().currentuserdetail;
        } catch (error) {
            throw new Error("Authguard::updateState Exception :" + error);
        }
    }

    autoSignIn(callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let tokenStr = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        let token = JSON.parse(tokenStr);
        if (token != null && token.accessToken != null) {
            this.store.dispatch(UserActions.setIsLoggedIn(true));
        }
        return callback;
        // this.http.post(this.BASEURL + CONSTANTS.API.REAUTHENTICATE, localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)).subscribe(response => {
        //     let res = true;
        //     let responseObj: any = response;
        //     if (responseObj.error === '') {
        //         //set token in local storage
        //         localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, responseObj.token);
        //         //this.store.dispatch(UserActions.setCurrentUserDetails({ response }));
        //     }
        //     else {
        //         res = false;
        //     }

        //     return callback && callback(res);
        // },
        //     (error) => {
        //         console.log(error);
        //         return callback && callback(false);
        //     });

    }

    authenticate(credentials: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;

        let data = {
            'msisdn': credentials.msisdn,
            'password': credentials.password
        }

        this.http.post(this.BASEURL + CONSTANTS.API.AUTHENTICATE, data).subscribe(response => {
            let res = true;
            let responseObj: any = response;

            if (responseObj.result === 'ok') {
                res = true;
                console.log("Username and password correct");
                this.store.dispatch(UserActions.setUserName(credentials.msisdn));
            }
            else {

                res = false;
            }

            return callback && callback(res, responseObj.debugInfo);
        },
            (error) => {
                console.log(error);
                return callback && callback(false, "Error occurred! Try again");
            });

    }

    authenticateconfirm(credentials: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;

        let data = {
            'msisdn': credentials.msisdn,
            'code': credentials.otp
        }

        this.http.post(this.BASEURL + CONSTANTS.API.AUTHENTICATECONFIRM, data).subscribe(response => {
            let res = true;
            let responseObj: any = response;

            if (responseObj.result === 'ok') {

                //set token in local storage
                let dto: any = {
                    accessToken: responseObj.data.accessToken,
                    refreshToken: responseObj.data.refreshToken,
                    username: credentials.msisdn
                }
                localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, JSON.stringify(dto));
                this.isLoggedIn = true;
                //this.store.dispatch(UserActions.setCurrentUserDetails({ response }));
            }
            else {

                res = false;
            }

            return callback && callback(res, responseObj.debugInfo);
        },
            (error) => {
                console.log(error);
                return callback && callback(false, "Error occurred! Try again");
            });

    }

    getCompanyCategories(start, length, callback: any) {

        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.COMPANYCATGEORIES + "?start=" + start + "&length=" + length).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    addjobpost(postData: any, type: string, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let generateURL: string = "";
        switch (type) {
            case "JOB":
                generateURL = this.BASEURL + CONSTANTS.API.ADDJOBPOST
                break;
            case "TENDER":
                generateURL = this.BASEURL + CONSTANTS.API.ADDTENDER
                break;
            case "COURSE":
                generateURL = this.BASEURL + CONSTANTS.API.ADDCOURSE;
                let dto: any = {
                    description: postData.description,
                    expireDate: postData.deadLine,
                    file: postData.file,
                    fileName: postData.fileName,
                    location: postData.location,
                    qualification: postData.qualification,
                    title: postData.name
                };
                postData = null;
                postData = dto;
                break;
            default:
                generateURL = this.BASEURL + CONSTANTS.API.ADDJOBPOST
                break;
        }
        this.http.post(generateURL, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    updatejobpost(postData: any, type: string, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let generateURL: string = "";
        switch (type) {
            case "JOB":
                generateURL = this.BASEURL + CONSTANTS.API.UPDATEJOBPOST
                break;
            case "TENDER":
                generateURL = this.BASEURL + CONSTANTS.API.UPDATETENDER
                break;
            case "COURSE":
                generateURL = this.BASEURL + CONSTANTS.API.UPDATECOURSE
                break;
            default:
                generateURL = this.BASEURL + CONSTANTS.API.UPDATEJOBPOST
                break;
        }

        this.http.put(generateURL, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getjobPostList(searchText: any, type: any, callback: any) {
        console.log("Search Text: ", searchText);
        let queryParams = new HttpParams();
        if (searchText) {
            if (searchText.categoryId) {
                queryParams = queryParams.append("categoryId", searchText.categoryId);
            }
            if (searchText.name) {
                queryParams = queryParams.append("name", searchText.name);
            }
            if (searchText.location) {
                queryParams = queryParams.append("location", searchText.location);
            }
        }

        let isLoggedIn: boolean = this.store.getState().isLoggedIn;
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let generateURL: string = "";
        switch (type) {
            case "JOB":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.JOBPOST : this.BASEURL + CONSTANTS.API.ADMINJOBLIST
                break;
            case "TENDER":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.LISTTENDER : this.BASEURL + CONSTANTS.API.ADMINTENDERLIST
                break;
            case "COURSE":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.LISTCOURSE : this.BASEURL + CONSTANTS.API.ADMINCOURSELIST
                break;
            default:
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.JOBPOST : this.BASEURL + CONSTANTS.API.ADMINJOBLIST
                break;
        }
        // console.log("GENERATE URL: ", generateURL);
        const httpOptions = {
            // headers: { 'Authorization': 'Basic ' + btoa(`${environment.username}:${environment.password}`)},
            params: queryParams
        };
        this.http.get(generateURL, httpOptions).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getIsLoggedIn() {
        return this.store.getState().isLoggedIn;
    }

    deleteJob(id: string, type: string, callback: any) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };

        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let generateURL: string = "";
        switch (type) {
            case "JOB":
                generateURL = this.BASEURL + CONSTANTS.API.DELETEJOB
                break;
            case "TENDER":
                generateURL = this.BASEURL + CONSTANTS.API.DELETETENDER
                break;
            case "COURSE":
                generateURL = this.BASEURL + CONSTANTS.API.DELETECOURSE
                break;
            default:
                break;
        }

        this.http.delete(generateURL, options).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    getImageFile(uuid: string, type: string, callback) {
        let isLoggedIn: boolean = this.store.getState().isLoggedIn;
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let generateURL: string = "";
        switch (type) {
            case "JOB":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.JOBIMAGEFILE + uuid : this.BASEURL + CONSTANTS.API.ADMINJOBIMAGEFILE + uuid
                break;
            case "TENDER":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.TENDERFILE + uuid : this.BASEURL + CONSTANTS.API.ADMINTENDERIMAGEFILE + uuid;
                break;
            case "COURSE":
                generateURL = isLoggedIn ? this.BASEURL + CONSTANTS.API.COURSEFILE + uuid : this.BASEURL + CONSTANTS.API.ADMINCOURSEIMAGEFILE + uuid;
                break;
        }

        // const httpOptions = {
        //     headers: { 'Authorization': 'Basic ' + btoa(`${environment.username}:${environment.password}`)}
        // };

        this.http.get(generateURL).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback('');
            });
    }

    endActiveSession() {
        try {
            //clear token from local storage
            //localStorage.clear();
            localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
            //remove user authnetication in app state
            this.store.dispatch(UserActions.setIsLoggedIn(false));
            this.store.dispatch(UserActions.setUserName(null));
        } catch (error) {
            throw new Error("AppService::endActiveSession exception: " + error);
        }
    }

    logout(postdata, callback: any) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);

        this.http.post(this.BASEURL + CONSTANTS.API.LOGOUT, postdata).subscribe(() => {
            return callback && callback(true);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }


    getBaseURL() {
        return this.BASEURL;
    }

    /**
     * 
     * @param postData 
     * @param callback 
     */
    signup(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.SIGNUP, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    confirmsignup(postData: any, callback: any) {
        console.log("Post data: ", postData);
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.CONFIRMSIGNUP, postData).subscribe(response => {
            let res = true;
            let responseObj: any = response;

            if (responseObj.result === 'ok') {

                //set token in local storage
                let dto: any = {
                    accessToken: responseObj.data.accessToken,
                    refreshToken: responseObj.data.refreshToken,
                    username: postData.msisdn
                }
                localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, JSON.stringify(dto));
                this.isLoggedIn = true;
                //this.store.dispatch(UserActions.setCurrentUserDetails({ response }));
            }
            else {

                res = false;
            }
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    resendotp(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.OTPRESEND, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    addtender(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.ADDTENDER, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    updatetender(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.put(this.BASEURL + CONSTANTS.API.UPDATETENDER, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getTenderList(searchText: any, callback: any) {

        let queryParams = new HttpParams();
        if (searchText) {
            if (searchText.categoryId != null) {
                queryParams = queryParams.append("categoryId", searchText.categoryId);
            }
            if (searchText.name != null) {
                queryParams = queryParams.append("name", searchText.name);
            }
            if (searchText.location != null) {
                queryParams = queryParams.append("location", searchText.location);
            }
        }


        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.LISTTENDER, { params: queryParams }).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    deleteTender(id: string, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let data = { id: id };

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };

        this.http.delete(this.BASEURL + CONSTANTS.API.DELETETENDER, options).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }


    addCourse(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.ADDCOURSE, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    updateCourse(postData: any, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.put(this.BASEURL + CONSTANTS.API.UPDATECOURSE, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getCourseList(searchText: any, callback: any) {

        let queryParams = new HttpParams();
        if (searchText) {
            if (searchText.categoryId != null) {
                queryParams = queryParams.append("categoryId", searchText.categoryId);
            }
            if (searchText.name != null) {
                queryParams = queryParams.append("name", searchText.name);
            }
            if (searchText.location != null) {
                queryParams = queryParams.append("location", searchText.location);
            }
        }


        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.LISTCOURSE, { params: queryParams }).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    deleteCourse(id: string, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        let data = { id: id };

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: id,
            },
        };

        this.http.delete(this.BASEURL + CONSTANTS.API.DELETECOURSE, options).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    setSelectedJobPost(jobpost) {
        this.store.dispatch(UserActions.setJobDetail(jobpost));
    }

    setSelectedCoursedetail(course) {
        this.store.dispatch(UserActions.setCourseDetail(course));
    }

    getCompanyProfile(callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.PROFILE).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getCompanyLogo(uuid, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        // const httpOptions = {
        //     headers: { 'Authorization': 'Basic ' + btoa(`${environment.username}:${environment.password}`)}
        // };
        this.http.get(this.BASEURL + CONSTANTS.API.COMPANYLOGO + uuid).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    saveCompanyProfile(postData, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.put(this.BASEURL + CONSTANTS.API.PROFILESAVE, postData).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    // Create Candidate
    createCandidate(data, callback: any) {
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.post(this.BASEURL + CONSTANTS.API.CREATECANDIDATE, data).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });

    }

    getCandidateList(pagination, callback){
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.VIEWCANDIDATES + `?length=${pagination.length}&start=${pagination.start}`).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    getDashboardStats(callback){
        // const httpOptions = {
        //     headers: { 'Authorization': 'Basic ' + btoa(`${environment.username}:${environment.password}`)}
        // };

        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.VIEWDASHBOARDSTATS).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    fetchProfileImages(uuid: string, callback){
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.FETCHCANDIDATEIMAGE + uuid).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

    downloadCV(uuid: string, callback){
        this.BASEURL = this.appconfig.getConfig("service").baseurl;
        this.http.get(this.BASEURL + CONSTANTS.API.FETCHCANDIDATECV + uuid).subscribe(response => {
            return callback && callback(response);
        },
            (error) => {
                console.log(error);
                return callback && callback(false);
            });
    }

}
