<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7">
                            <img src="./assets/img/home-1/1.jpg" alt="Portal">
                        </div>

                        <div class="col-lg-5">
                            <img src="./assets/img/home-1/2.jpg" alt="Portal">
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right portal-right-two">
                    <h2>Trusted & Popular Job Portal</h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur.</p>

                    <div class="portal-counter-area">
                        <div class="row">
                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">14</span>K+</h3>
                                    <p>Job Available</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">18</span>K+</h3>
                                    <p>Submitted CV</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-4 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">9</span>K+</h3>
                                    <p>Company</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-4 offset-lg-2">
                                <div class="counter-item">
                                    <h3><span class="counter">22</span>K+</h3>
                                    <p>Registered Member</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-4">
                                <div class="counter-item">
                                    <h3><span class="counter">55</span>K+</h3>
                                    <p>Appointed to Job</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">14</span>k+</h3>
                    <p>Job Available</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3><span class="counter">18</span>k+</h3>
                    <p>CV Submitted</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3><span class="counter">9</span>k+</h3>
                    <p>Companies</p>
                </div>
            </div>

            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3><span class="counter">35</span>+</h3>
                    <p>Appointed to Job</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popular-area pt-100 pb-170">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <img src="./assets/img/home-1/3.jpg" alt="Popular">
                        </div>

                        <div class="col-lg-5">
                            <div class="practice-inner">
                                <img src="./assets/img/home-1/4.jpg" alt="Popular">
                                <img src="./assets/img/home-1/5.jpg" alt="Popular">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>Why We are Most Popular</h2>
                    </div>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida</p>

                    <div class="row popular-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                <li><i class="flaticon-employee"></i> International Job</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>