<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Post A {{type}}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Post A {{type}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="post-job-area pt-100">
    <div class="container">
        <div class="post-job-item">
            <form [formGroup]="jobForm">
                <div class="post-job-heading">
                    <h2>Post Your {{type}}</h2>
                </div>

                <div class="row">
                    <div class="col-lg-6" hidden>
                        <div class="form-group">
                            <label>{{type}} ID</label>
                            <input type="text" class="form-control" placeholder="ID" formControlName="id"
                                id="id">

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>{{type}} Title</label>
                            <input type="text" class="form-control" placeholder="UX/UI Designer" formControlName="name"
                                id="name">
                            <div class="alert alert-warning"
                                *ngIf="name && name.errors && name.errors.required  && (name.dirty || name.touched)">
                                title is required</div>

                        </div>
                    </div>

                    <div class="col-lg-6" *ngIf="type.toUpperCase() != 'COURSE'">
                        <div class="form-group">
                            <label>{{type}} Category</label>
                            <div class="job-category-area">
                                <select formControlName="categoryId" nice-select>
                                    <option *ngFor="let category of categories" [ngValue]="category['id']">
                                        {{ category['name'] }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6" *ngIf="type.toUpperCase() == 'COURSE'">
                        <div class="form-group">
                            <label>{{type}} Qualification</label>
                            <input type="text" class="form-control" placeholder="Qualification" formControlName="qualification"
                                id="qualification">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Deadline</label>
                            <input type="datetime-local" class="form-control" placeholder="2023-01-01 00:00:00"
                                formControlName="deadLine">
                            <div class="alert alert-warning"
                                *ngIf="deadLine && deadLine.errors && deadLine.errors.required  && (deadLine.dirty || deadLine.touched)">
                                deadLine is required</div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="job-currency-area">
                                <label>File</label>
                                <input type="file" class="form-control" placeholder="sample.pdf" (change)="handleFileInput($event)">
                                <div class="alert alert-warning"
                                *ngIf="file && file.errors && file.errors.required  && (file.dirty || file.touched)">
                                file is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>WebLink</label>
                            <input type="text" class="form-control" placeholder="www.placeholder.com"
                                formControlName="webLink">
                            <div class="alert alert-warning"
                                *ngIf="webLink && webLink.errors && webLink.errors.required  && (webLink.dirty || webLink.touched)">
                                webLink is required</div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Location</label>
                            <input type="text" class="form-control" formControlName="location"
                                placeholder="210-27 Quadra, Market Street, Victoria Canada">
                            <div class="alert alert-warning"
                                *ngIf="location && location.errors && location.errors.required  && (location.dirty || location.touched)">
                                location is required</div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>ApplyLink</label>
                            <input type="text" class="form-control" placeholder="www.applylink.com"
                                formControlName="applyLink">
                            <div class="alert alert-warning"
                                *ngIf="applyLink && applyLink.errors && applyLink.errors.required  && (applyLink.dirty || applyLink.touched)">
                                applyLink is required</div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12">
                        <div class="form-group">
                            <div class="job-type-area">
                                <span>{{type}} Type</span>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio1" value="option1" checked>
                                    <label class="form-check-label" for="inlineRadio1">Full Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio2" value="option2">
                                    <label class="form-check-label" for="inlineRadio2">Part Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio3" value="option2">
                                    <label class="form-check-label" for="inlineRadio3">Intern</label>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>{{type}} Description</label>
                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                            <ngx-editor [editor]="editor"  formControlName="description" [disabled]="false" placeholder="'Type here...'"></ngx-editor>

                            <!-- <textarea id="your_message" rows="8" class="form-control"
                                formControlName="description"></textarea> -->
                            <div class="alert alert-warning"
                                *ngIf="description && description.errors && description.errors.required  && (description.dirty || description.touched)">
                                description is required</div>
                        </div>
                    </div>
                </div>

                <div class="text-left">
                    <button type="submit" class="btn create-ac-btn" (click)="onSubmit()">Post</button>
                    <button type="submit" class="btn create-blue-btn  mx-3" (click)="setDefaultValues()">Reset</button>
                </div>
            </form>
        </div>
    </div>

</div>