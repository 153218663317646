<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Single Resume</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Single Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-resume-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="single-resume-item">
                    <div class="single-resume-profile">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <img src="./assets/img/single-profile/3.jpg" alt="Resume">
                            </div>

                            <div class="col-lg-7">
                                <h3>Jery Hudson</h3>
                                <span><i class="icofont-location-pin"></i> Barmingham, UK</span>
                                <p><i class="icofont-hand-drawn-right"></i> Web Consultant</p>
                                <p><i class="icofont-clock-time"></i> Updated 1 year ago</p>
                            </div>
                        </div>
                    </div>

                    <div class="single-resume-description">
                        <h3>Description</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                    </div>

                    <div class="single-resume-skills">
                        <h3>Skills</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo delectus voluptate harum maxime nihil facilis incidunt quae aspernatur est officiis minus blanditiis perspiciatis, tempora aliquid quaerat quasi soluta saepe earum.</p>
                        <ul>
                            <li><i class="icofont-hand-drawn-right"></i> Web Developer</li>
                            <li><i class="icofont-hand-drawn-right"></i> UX/UI Designer</li>
                            <li><i class="icofont-hand-drawn-right"></i> Marketing</li>
                            <li><i class="icofont-hand-drawn-right"></i> SEO</li>
                        </ul>
                    </div>

                    <div class="single-resume-education">
                        <h3>Education</h3>
                        <span>Senior Web Designer at Gable International University</span>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident veniam omnis reprehenderit excepturi magnam modi.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="single-resume-item">
                    <div class="single-resume-category">
                        <h3>Job Categories</h3>

                        <ul>
                            <li><a routerLink="/">Web Developer</a><span>(5)</span></li>
                            <li><a routerLink="/">Web Designer</a><span>(2)</span></li>
                            <li><a routerLink="/">UX/UI Designer</a><span>(7)</span></li>
                            <li><a routerLink="/">Marketing</a><span>(0)</span></li>
                            <li><a routerLink="/">SEO</a><span>(1)</span></li>
                            <li><a routerLink="/">Networking</a><span>(0)</span></li>
                        </ul>
                    </div>
                    
                    <div class="single-resume-category">
                        <h3>Job Locations</h3>

                        <ul>
                            <li><a routerLink="/">New York</a><span>(4)</span></li>
                            <li><a routerLink="/">Washington</a><span>(5)</span></li>
                            <li><a routerLink="/">Chicago</a><span>(3)</span></li>
                            <li><a routerLink="/">Houston</a><span>(1)</span></li>
                            <li><a routerLink="/">Los Angeles</a><span>(0)</span></li>
                        </ul>
                    </div>

                    <div class="single-resume-category single-resume-types">
                        <h3>Job Types</h3>

                        <ul>
                            <li><a routerLink="/">Freelance</a><span>(11)</span></li>
                            <li><a routerLink="/">Part Time</a><span>(9)</span></li>
                            <li><a routerLink="/">Full Time</a><span>(7)</span></li>
                            <li><a routerLink="/">Internship</a><span>(13)</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>